import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import { Modal, Form, Dropdown } from "react-bootstrap";
import useHeader from "../../../utils/useHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFavorit,
  setAllFavorit,
} from "../../../PagesCrm/Redux/slices/actions.slice";

const Favorit = ({ iduser }) => {
  const [favorit, setFavorit] = useState([]);
  const [favoritSelected, setFavoritSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [userName, setUserName] = useState("");
  const [pwd, setPwd] = useState("");
  const [copied, setCopied] = useState(false);
  const [copiepwd, setCopiepwd] = useState(false);

  const dispatch = useDispatch();

  const GET_ALL_FAVORIT = useSelector(getAllFavorit);

  const Header = useHeader();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setName("");
    setLink("");
    setUserName("");
    setPwd("");
  };

  const handleCloseModalDetail = () => {
    setShowModalDetail(false);
    setShowModal(true);
  };

  const getAll = () => {
    let obj = {
      iduser: iduser,
    };
    axios
      .post(`${config.apiAdmin}/sadmin/favorit/read_all`, obj, Header)
      .then((response) => {
        // setFavorit(response.data);
        dispatch(setAllFavorit(response.data));
      })
      .catch((error) => {
        console.error("There was an error!", error);
        dispatch(setAllFavorit([]))
      });
  };

  const handleSubmit = () => {
    const obj = {
      name,
      link,
      iduser,
      user_name: userName,
      pwd,
    };

    axios
      .post(`${config.apiAdmin}/sadmin/favorit/create`, obj, Header)
      .then((response) => {
        // setFavorit([...favorit, response.data]);
        handleCloseModal();
        getAll();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleToggleActivation = (idfavorit) => {
    const obj = {
      idfavorit: idfavorit,
    };
    axios
      .patch(`${config.apiAdmin}/sadmin/favorit/toggleActivation`, obj, Header)
      .then((response) => {
        // getAll();
        // Update the favorit state with the new activation status
        // setFavorit(response.data)
        dispatch(
          setAllFavorit((prevFavorit) =>
            prevFavorit.map((item) =>
              item.idfavorit === idfavorit
                ? { ...item, is_active: response.data.is_active }
                : item
            )
          )
        );
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const deleteFavorit = (idfavorit) => {
    const obj = {
      idfavorit: idfavorit,
    };
    axios
      .delete(`${config.apiAdmin}/sadmin/favorit/delete_favorit`, {
        data: obj,
        headers: Header.headers,
      })
      .then((response) => {
        getAll()
        if(response.message === "favori non trouvé avec l'id."){
          // dispatch(setAllFavorit([]))
          setShowModalDetail(false);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // getAll();
      });
  };

  const ShowModal = (item) => {
    setShowModal(false);
    setShowModalDetail(true);
    setFavoritSelected(item);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setCopied(false);
      setCopiepwd(false);
    }, 2000);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      <Dropdown.Toggle
        as="div"
        className="nav-link i-false c-pointer"
      ></Dropdown.Toggle>
      <Dropdown.Menu
        align="end"
        className="mt-2 dropdown-menu dropdown-menu-end"
      >
        <Dropdown.Header>
          <button className="btn btn-primary" onClick={handleShowModal}>
            Gerer/Ajouter les Favoris
          </button>
        </Dropdown.Header>
        {GET_ALL_FAVORIT?.filter((item) => item.is_active === 1).map((item) => {
          const url = item.link.startsWith("http")
            ? item.link
            : `https://${item.link}`;

          return (
            <Dropdown.Item
              key={item.idfavorit}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="fs-4 text-uppercase ps-4"
            >
              {item.name}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form className="mb-3">
            <Form.Group controlId="formName">
              <Form.Label>Nom du lien</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formLink" className="mt-3">
              <Form.Label>Lien URL</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formUser" className="mt-3">
              <Form.Label>User</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPwd" className="mt-3">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
              />
            </Form.Group>
            <button
              type="button"
              className={`px-4 py-2 rounded-3 bg-green-target text-black fw-bold border-0 mt-3 ${
                !name || !link ? "opacity-50" : ""
              }`}
              onClick={handleSubmit}
              disabled={!name || !link}
            >
              valider
            </button>
          </Form>
          {GET_ALL_FAVORIT.map((item) => (
            <div className="d-flex align-items-center justify-content-between">
              {/* <div className="form-check col-1 p-0 mb-1 ms-2">
                <input
                  className="form-check-input p-0 m-0"
                  style={{ width: "20px", height: "20px" }}
                  type="checkbox"
                  id={`checkBox-${item.idfavorit}`}
                  checked={item.is_active !== 0}
                  onChange={() => {
                    handleToggleActivation(item.idfavorit);
                  }}
                />
              </div> */}
              <div
                key={item.idfavorit}
                className="d-flex align-items-center my-2 rounded-1 hover-grey col-auto"
                onClick={() => ShowModal(item)}
              >
                <span className="ms-2 p-0 m-2 col-10 text-uppercase fs-16">
                  {item.name}
                </span>
                {/* <div
                className="cursor-pointer"
                onClick={() => {
                  deleteFavorit(item.idfavorit);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="red"
                  className="bi bi-trash3 zoom"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                </svg>
              </div> */}
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  deleteFavorit(item.idfavorit);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="red"
                  className="bi bi-trash3 zoom"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                </svg>
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal show={showModalDetail} onHide={handleCloseModalDetail}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <div className="fs-4 text-black mb-3">
              informations relatives au favori
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <div className="fs-3 text-black underline-black fw-bold">Nom du favori</div>
                <h3>{favoritSelected.name}</h3>
              </div>
              <div>
                <div className="fs-3 text-black text-end fw-bold underline-black">lien</div>
                <h3>
                  <a
                    href={`https://${favoritSelected.link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-info"
                  >
                    {favoritSelected.link}
                  </a>
                </h3>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              {favoritSelected.user_name && (
              <div>
                <div className="fs-3 text-black mb-2 fw-bold underline-black">UserName</div>
                <div className="d-flex align-items-center">
                  {" "}
                  <button
                    onClick={() => {
                      copyToClipboard(favoritSelected.user_name);
                      setCopied(true);
                    }}
                    className="btn bg-vertlight btn-sm p-1 px-3 text-black text-start me-2"
                  >
                    Copier
                  </button>
                  {copied && <span className="ms-2 text-success">Copié!</span>}
                  <h3 className="m-0">{truncateText(favoritSelected.user_name, 12)}</h3>
                </div>
              </div>
              )}
              {favoritSelected.pwd && (
              <div>
                <div className="fs-3 text-black text-end fw-bold mb-2 underline-black">
                  Mot de passe
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <h3 className="m-0">{truncateText(favoritSelected.pwd, 12)}</h3>
                  <button
                    onClick={() => {
                      copyToClipboard(favoritSelected.pwd);
                      setCopiepwd(true);
                    }}
                    className="btn bg-vertlight btn-sm ms-2 p-1 px-3 text-black"
                  >
                    Copier
                  </button>
                  {copiepwd && (
                    <span className="ms-2 text-success">Copié!</span>
                  )}
                </div>
              </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Favorit;
