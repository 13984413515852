import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect, { components } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { CircleLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import {
  setAllContracts,
  setFilters,
  getFilters,
} from "../../Redux/slices/contract.slice";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import useHeader from "../../../utils/useHeader";
import { SVGICON } from "../../../jsx/constant/theme";
import { styleFilters } from "../../Manager/Tableau/TableauUtils/Utils";
import { handleDateChange } from "../../Global/Table/Functions";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const TableauCrmFilters = (props) => {
  const AdminDetails = useSelector(getAdminDetails);
  const desksToAdmin = AdminDetails?.desk;
  const brandsToAdmin = AdminDetails?.brand;
  const idusers = AdminDetails?.idusers
  const teamAntoine = ['8e1c25be-73dd-40b2-9bbc-da309b671a68', 'e651cac3-91f4-4e16-bb2a-bc44511ff52d', '90e39a84-5004-4620-8782-923aa7c8a7b9', '5ca7998c-d1b8-4a91-a5a7-eb8c7f89300f', '1f8e2cfc-d744-4530-a389-b17c2dee3ab0']

  const deskParams = useParams().desk;

  const Header = useHeader();

  let Desk = deskParams ? deskParams : desksToAdmin;

  const dispatch = useDispatch();

  const filters = useSelector(getFilters);

  const initialObjToFetch = {
    filters: {
      desk: Desk,
      // agt_supp_id: idToAdmin
    },
    filters_date: {},
    sorts: {},
    limit: "",
  };

  const [inputSearchCustomerfname, setInputSearchCustomerfname] = useState("");
  const [inputSearchCustomerlname, setInputSearchCustomerlname] = useState("");
  const [inputSearchCustomerlnamefname, setInputSearchCustomerlnamefname] =
    useState("");
  const [ObjToFetch, setObjToFetch] = useState(initialObjToFetch);
  const [psp, setPsp] = useState([]);
  const [neobank, setNeoBank] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);

  const [dropDispo, setDropDispo] = useState(null);
  const [dropPreuve, setDropPreuve] = useState(null);
  const [dropEncaisse, setDropEncaisse] = useState(null);
  const [dropReport, setDropReport] = useState(null);
  const [dropAnnul, setDropAnnul] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [listSupport, setListSupport] = useState([]);
  const [listSellAndTlead, setListSellAndTlead] = useState([]);

  const [LoaderStart, setLoaderStart] = useState(false);

  const [showBtnFilter, setShowBtnFilter] = useState(false);

  const optionsSatutsDispo = [
    {
      value: "argent_dispo_check",
      label: "argent dispo_oui",
      checked: "1",
      Tofetch: "argent_dispo",
      rep: "oui",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      ),
    },
    {
      value: "argent_dispo",
      label: "argent dispo_non",
      checked: "0",
      Tofetch: "argent_dispo",
      rep: "non",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        </svg>
      ),
    },
  ];
  const optionsSatutsPreuve = [
    {
      value: "preuve_check",
      label: "preuve_oui",
      checked: "1",
      Tofetch: "preuve",
      rep: "oui",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      ),
    },
    {
      value: "preuve",
      label: "preuve_non",
      checked: "0",
      Tofetch: "preuve",
      rep: "non",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        </svg>
      ),
    },
  ];
  const optionsSatutsEncaisse = [
    {
      value: "encaisse_check",
      label: "encaisse_oui",
      checked: "1",
      Tofetch: "encaisse",
      rep: "oui",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      ),
    },
    {
      value: "encaisse",
      label: "encaisse_non",
      checked: "0",
      Tofetch: "encaisse",
      rep: "non",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        </svg>
      ),
    },
  ];
  const optionsSatutsReport = [
    {
      value: "is_report_check",
      label: "is report_oui",
      checked: "1",
      Tofetch: "is_report",
      rep: "oui",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      ),
    },
    {
      value: "is_report",
      label: "is report_non",
      checked: "0",
      Tofetch: "is_report",
      rep: "non",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        </svg>
      ),
    },
  ];
  const optionsSatutsAnnul = [
    {
      value: "is_canceled_check",
      label: "is canceled_oui",
      checked: "1",
      Tofetch: "is_canceled",
      rep: "oui",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      ),
    },
    {
      value: "is_canceled",
      label: "is canceled_non",
      checked: "0",
      Tofetch: "is_canceled",
      rep: "non",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        </svg>
      ),
    },
  ];

  const pspSelect = psp?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const brandSelect = brandsToAdmin?.map((brand) => ({
    value: brand,
    label: brand,
  }));
  const neobankSelect = neobank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const customerBankSelect = customerBank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const optionsSupport = listSupport?.map((support) => ({
    value: support.idusers,
    label: support.pseudo,
    profil:
      support.profil === "agt_supp"
        ? "Support"
        : support.profil === "mng_supp"
          ? "MgSupport"
          : support.profil === "boss_supp"
            ? "BossSupport"
            : support.profil,
  }));
  const optionsPseudo = listSellAndTlead?.map((pseudo) => {
    return {
      value: pseudo.idusers,
      label: pseudo.pseudo,
      profil:
        pseudo.profil === "sell"
          ? "Vendeur"
          : pseudo.profil === "tlead"
            ? "TeamLeader"
            : pseudo.profil,
    };
  });

  useEffect(() => {
    if (props.showFilters === true) {
      FetchFiltersGet("/psp/read_all", setPsp);
      FetchFiltersGet("/neobanks/read_all", setNeoBank);
      FetchFiltersGet("/customerbanks/read_all", setCustomerBank);
      FetchFiltersPost("/users/all_agt_supp_and_mng_supp", setListSupport);
      FetchFiltersPost("/users/all_sell_and_tlead", setListSellAndTlead, {
        desk: desksToAdmin,
      });
    }
    // eslint-disable-next-line
  }, [props.showFilters]);

  const FetchFiltersGet = (url, fonction) => {
    axios
      .get(`${config.apiAdmin}/sadmin${url}`, Header)
      .then((response) => {
        fonction(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const FetchFiltersPost = (url, fonction, obj) => {
    axios
      .post(`${config.apiAdmin}/sadmin${url}`, obj, Header)
      .then((response) => {
        fonction(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const SearchContrats = (options) => {
    let IsInit;
    if (options.isReinit) {
      IsInit = {
        filters: {
          desk: Desk,
          // agt_supp_id: idToAdmin
        },
        filters_date: {},
        sorts: {},
        limit: "",
      };
    } else {
      IsInit = filters;
    }
    setLoaderStart(true);
    axios
      .post(
        `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
        IsInit,
        Header
      )
      .then((response) => {
        dispatch(setAllContracts(response.data.data.results));
        setLoaderStart(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoaderStart(false);
      });
  };

  const reinitFilters = () => {
    setShowBtnFilter(false);
    dispatch(
      setFilters({
        filters: {
          desk: Desk,
        },
        filters_date: {},
        sorts: {},
        limit: "",
      })
    );
    setObjToFetch({
      filters: {
        desk: Desk,
      },
      filters_date: {},
      sorts: {},
      limit: "",
    });
    setInputSearchCustomerfname("");
    setInputSearchCustomerlname("");
    setInputSearchCustomerlnamefname("");
    setDropAnnul(null);
    setDropDispo(null);
    setDropPreuve(null);
    setDropEncaisse(null);
    setDropReport(null);
    SearchContrats({ isReinit: true });
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    dispatch(setFilters(ObjToFetch));
    // eslint-disable-next-line
  }, [ObjToFetch]);

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.data.rep}
        </div>
      </components.Option>
    );
  };

  const CustomDrop = (props) => {
    return (
      <components.Option {...props}>
        <div className="d-flex justify-content-between align-items-center text-black">
          <span>{props.data.label}</span>
          <span
            style={{ color: "white" }}
            className={`badge badge-${props.data.profil}`}
          >
            {props.data.profil}
          </span>
        </div>
      </components.Option>
    );
  };

  const datePickerStyle = {
    paddingRight: "30px", // Pour laisser de l'espace à droite pour une icône
    border: "1px solid #ced4da",
    borderRadius: "4px",
    height: "38px", // Ajuster la hauteur selon vos besoins
    width: "100%", // Pour s'assurer qu'il prend tout l'espace disponible
    boxSizing: "border-box", // Pour inclure le padding et la border dans la largeur totale
    paddingLeft: "10px", // Pour donner un peu d'espace à gauche aussi
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setObjToFetch((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        is_active: isChecked ? 0 : 1,
      },
    }));
    console.log(`Checkbox is_active set to ${isChecked}`);
  };

  const inputStyle = {
    height: "36px",
    border: "1px solid #CCCCCC",
    color: "#808080",
    fontSize: "0.9rem",
  };

  const handleSelectChange = (
    selectedOptions,
    filterKey,
    isMulti = true,
    set
  ) => {
    setShowBtnFilter(true);
    if (set) {
      set(selectedOptions);
    }

    setObjToFetch((prevState) => {
      const updatedFilters = { ...prevState.filters };
      if (isMulti) {
        if (selectedOptions && selectedOptions.length > 0) {
          updatedFilters[filterKey] = selectedOptions.map(
            (option) => option.value
          );
        } else {
          delete updatedFilters[filterKey];
        }
      } else {
        if (selectedOptions) {
          updatedFilters[selectedOptions.Tofetch] = selectedOptions.checked;
        } else {
          delete updatedFilters[filterKey];
        }
      }
      return {
        ...prevState,
        filters: updatedFilters,
      };
    });
  };

  return (
    <div className="my-2 p-0">
      {LoaderStart ? (
        <div className="sweet-loading" style={{ marginTop: "100px" }}>
          <CircleLoader
            color="#36d7b7"
            loading="true"
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <div className="mt-2 mb-2 trait-filtre"></div>
          <div className="d-flex">
            <div className="d-flex flex-column mx-2 justify-content-end">
              <label htmlFor="" className="mt-1">
                Client
              </label>
              <input
                type="text"
                value={inputSearchCustomerfname}
                onChange={(e) => {
                  setShowBtnFilter(true);
                  setInputSearchCustomerfname(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      cust_fname: e.target.value,
                    },
                  }));
                }}
                className="rounded-1 ps-2 mb-2"
                id="input-search"
                placeholder="Prenom"
                style={inputStyle}
              />
              <input
                type="text"
                value={inputSearchCustomerlname}
                onChange={(e) => {
                  setShowBtnFilter(true);
                  setInputSearchCustomerlname(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      cust_lname: e.target.value,
                    },
                  }));
                }}
                className="rounded-1 ps-2"
                id="input-search"
                placeholder="Nom"
                style={inputStyle}
              />
              <input
                type="text"
                value={inputSearchCustomerlnamefname}
                onChange={(e) => {
                  setShowBtnFilter(true);
                  setInputSearchCustomerlnamefname(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      cust_lname: e.target.value,
                      cust_fname: e.target.value,
                    },
                  }));
                }}
                className="rounded-1 ps-2 mt-2"
                id="input-search"
                placeholder="Nom ou Prenom"
                style={inputStyle}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <label htmlFor="">Pseudo</label>
              <ReactSelect
                options={optionsPseudo}
                className="drop-search my-1"
                placeholder="Seller"
                value={
                  optionsPseudo.filter(
                    (option) =>
                      ObjToFetch.filters.pseudo_seller &&
                      ObjToFetch.filters.pseudo_seller.includes(option.value)
                  ) || undefined
                }
                onChange={(selectedOptions) =>
                  handleSelectChange(selectedOptions, "pseudo_seller")
                }
                components={{ Option: CustomDrop }}
                styles={styleFilters()}
                isSearchable={true}
                isClearable={true}
                isMulti={true} // Permet la sélection multiple
              />

              <ReactSelect
                options={optionsSupport}
                className="mt-1"
                placeholder="Support"
                value={
                  optionsSupport.filter(
                    (option) =>
                      ObjToFetch.filters.pseudo_agt_supp &&
                      ObjToFetch.filters.pseudo_agt_supp.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) =>
                  handleSelectChange(selectedOptions, "pseudo_agt_supp")
                }
                components={{ Option: CustomDrop }}
                isSearchable={true}
                isClearable={true}
                isMulti={true} // Permet la sélection multiple
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <label htmlFor="">Contrat</label>
              <ReactSelect
                options={pspSelect}
                className="drop-search my-1"
                classNamePrefix="psp"
                placeholder="PSP"
                value={
                  pspSelect.filter(
                    (option) =>
                      ObjToFetch.filters.psp &&
                      ObjToFetch.filters.psp.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) =>
                  handleSelectChange(selectedOptions, "psp")
                }
                isClearable={true}
                isMulti={true}
              />
              <ReactSelect
                options={neobankSelect}
                className="mt-1"
                classNamePrefix="neobank"
                placeholder="Plateforme"
                value={
                  neobankSelect.filter(
                    (option) =>
                      ObjToFetch.filters.neobank &&
                      ObjToFetch.filters.neobank.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) =>
                  handleSelectChange(selectedOptions, "neobank")
                }
                isClearable={true}
                isMulti={true}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <ReactSelect
                options={customerBankSelect}
                className="mb-2"
                classNamePrefix="customerBank"
                placeholder="Bank Client"
                value={
                  customerBankSelect.filter(
                    (option) =>
                      ObjToFetch.filters.customerbank &&
                      ObjToFetch.filters.customerbank.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) =>
                  handleSelectChange(selectedOptions, "customerbank")
                }
                isClearable={true}
                isMulti={true}
              />

              <ReactSelect
                options={brandSelect}
                className=""
                classNamePrefix="Brand"
                placeholder="Brand"
                value={
                  brandSelect.filter(
                    (option) =>
                      ObjToFetch.filters.brand &&
                      ObjToFetch.filters.brand.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) =>
                  handleSelectChange(selectedOptions, "brand")
                }
                isClearable={true}
                isMulti={true}
              />
            </div>

            <div className="d-flex flex-column mx-2 justify-content-end">
              <label htmlFor="">Status</label>
              <ReactSelect
                options={optionsSatutsDispo}
                className="mb-2"
                classNamePrefix="status"
                placeholder="Argent dispo"
                value={dropDispo}
                styles={styleFilters()}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    selectedOption,
                    dropDispo?.Tofetch,
                    false,
                    setDropDispo
                  )
                }
                components={{ Option: CustomOption }}
                isClearable={true}
              />
              <ReactSelect
                options={optionsSatutsPreuve}
                className=""
                classNamePrefix="status"
                placeholder="Preuve"
                value={dropPreuve}
                styles={styleFilters()}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    selectedOption,
                    dropPreuve?.Tofetch,
                    false,
                    setDropPreuve
                  )
                }
                components={{ Option: CustomOption }}
                isClearable={true}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <ReactSelect
                options={optionsSatutsReport}
                className="mb-2"
                classNamePrefix="status"
                placeholder="Report"
                value={dropReport}
                styles={styleFilters()}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    selectedOption,
                    dropReport?.Tofetch,
                    false,
                    setDropReport
                  )
                }
                components={{ Option: CustomOption }}
                isClearable={true}
              />
              <ReactSelect
                options={optionsSatutsAnnul}
                className=""
                classNamePrefix="status"
                placeholder="Annul"
                value={dropAnnul}
                styles={styleFilters()}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    selectedOption,
                    dropAnnul?.Tofetch,
                    false,
                    setDropAnnul
                  )
                }
                components={{ Option: CustomOption }}
                isClearable={true}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <ReactSelect
                options={optionsSatutsEncaisse}
                className=""
                classNamePrefix="status"
                placeholder="Encaisse"
                value={dropEncaisse}
                styles={styleFilters()}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    selectedOption,
                    dropEncaisse?.Tofetch,
                    false,
                    setDropEncaisse
                  )
                }
                components={{ Option: CustomOption }}
                isClearable={true}
              />
            </div>


            {teamAntoine.includes(idusers) && (
              <div className="d-flex flex-column mx-2 justify-content-end">
                <div className="mx-4 mb-1">Contrats archivés</div>
                <div className="d-flex align-items-center ms-4 border-bot-filter me-3">
                  <Form.Check
                    type="switch"
                    className="custom-switch-drop py-1"
                    style={{ transform: "scale(1)" }}
                    checked={ObjToFetch.filters.is_active === 0}
                    onChange={handleCheckboxChange}
                  />
                  <div className="m-0 text-archived">Non / oui</div>
                </div>
                <div className="d-flex align-items-center col-2 text-end mt-2 ">
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <DatePicker
                      selected={startDate}
                      onChange={(dates) =>
                        handleDateChange(
                          dates,
                          setShowBtnFilter,
                          setStartDate,
                          setEndDate,
                          setObjToFetch
                        )
                      }
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      isClearable
                      placeholderText="Dates"
                      className="input_date_filter ms-2 pointer"
                      popperClassName="custom-datepicker-popper"
                      style={datePickerStyle} // Appliquer le style à l'input
                    />
                    {SVGICON.BiCalendarIcon}
                  </div>
                </div>
              </div>
            )}



          </div>
          <div className="d-flex mt-2 justify-content-end align-items-center">
            <div className="d-flex align-items-center col-3 justify-content-end">
              {showBtnFilter && (
                <Button onClick={reinitFilters} className="p-2 me-5">
                  reinit. filtres
                </Button>
              )}
              <Button
                onClick={() => SearchContrats({ isReinit: false })}
                className="p-2 px-3"
              >
                Search
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TableauCrmFilters;
