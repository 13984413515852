import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect, { components } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { CircleLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import {
  setAllContracts,
  setFilters,
  getFilters,
} from "../../Redux/slices/contract.slice";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import useheader from "../../../utils/useHeader";
import {
  optionsSatutsDispo,
  optionsSatutsPreuve,
  optionsSatutsEncaisse,
  optionsSatutsReport,
  optionsSatutsAnnul,
} from "./TableauUtils/OptionsStatus";
import { SVGICON } from "../../../jsx/constant/theme";
import { styleFilters } from "./TableauUtils/Utils";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const TableauCrmFilters = (props) => {
  const AdminDetails = useSelector(getAdminDetails);
  const desksToAdmin = AdminDetails?.desk;
  const brandsToAdmin = AdminDetails?.brand;
  const idusers = AdminDetails?.idusers
  const teamAntoine = ['8e1c25be-73dd-40b2-9bbc-da309b671a68', 'e651cac3-91f4-4e16-bb2a-bc44511ff52d', '90e39a84-5004-4620-8782-923aa7c8a7b9', '5ca7998c-d1b8-4a91-a5a7-eb8c7f89300f', '1f8e2cfc-d744-4530-a389-b17c2dee3ab0']

  const deskParams = useParams().desk;

  let Desk = deskParams ? deskParams : desksToAdmin;

  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const Header = useheader();

  const initialObjToFetch = {
    filters: {
      desk: Desk,
      // agt_supp_id: idToAdmin
    },
    filters_date: {},
    sorts: {},
    limit: "",
  };

  const [inputSearchCustomerfname, setInputSearchCustomerfname] = useState("");
  const [inputSearchCustomerlname, setInputSearchCustomerlname] = useState("");
  const [inputSearchCustomerlnamefname, setInputSearchCustomerlnamefname] =
    useState("");
  const [objToFetch, setObjToFetch] = useState(initialObjToFetch);
  const [psp, setPsp] = useState([]);
  const [neobank, setNeoBank] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);
  const [dropDispo, setDropDispo] = useState(null);
  const [dropPreuve, setDropPreuve] = useState(null);
  const [dropEncaisse, setDropEncaisse] = useState(null);
  const [dropReport, setDropReport] = useState(null);
  const [dropAnnul, setDropAnnul] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateEncaisse, setStartDateEncaisse] = useState(null);
  const [endDateEncaisse, setEndDateEncaisse] = useState(null);
  const [listSupport, setListSupport] = useState([]);
  const [listSellAndTlead, setListSellAndTlead] = useState([]);
  const [LoaderStart, setLoaderStart] = useState(false);
  const [showBtnFilter, setShowBtnFilter] = useState(false);

  const pspSelect = psp?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const brandSelect = brandsToAdmin?.map((brand) => ({
    value: brand,
    label: brand,
  }));
  const neobankSelect = neobank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const customerBankSelect = customerBank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const optionsSupport = listSupport?.map((support) => ({
    value: support.idusers,
    label: support.pseudo,
    profil:
      support.profil === "agt_supp"
        ? "Support"
        : support.profil === "mng_supp"
          ? "MgSupport"
          : support.profil === "boss_supp"
            ? "BossSupport"
            : support.profil,
  }));
  const optionsPseudo = listSellAndTlead?.map((pseudo) => {
    return {
      value: pseudo.idusers,
      label: pseudo.pseudo,
      profil:
        pseudo.profil === "sell"
          ? "Vendeur"
          : pseudo.profil === "tlead"
            ? "TeamLeader"
            : pseudo.profil,
    };
  });

  useEffect(() => {
    if (props.showFilters === true) {
      FetchFiltersGet("/psp/read_all", setPsp);
      FetchFiltersGet("/neobanks/read_all", setNeoBank);
      FetchFiltersGet("/customerbanks/read_all", setCustomerBank);
      FetchFiltersPost("/users/all_agt_supp_and_mng_supp", setListSupport);
      FetchFiltersPost("/users/all_sell_and_tlead", setListSellAndTlead, {
        desk: desksToAdmin,
      });
    }
    // eslint-disable-next-line
  }, [props.showFilters]);

  const FetchFiltersGet = (url, fonction) => {
    axios
      .get(`${config.apiAdmin}/sadmin${url}`, Header)
      .then((response) => {
        fonction(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const FetchFiltersPost = (url, fonction, obj) => {
    axios
      .post(`${config.apiAdmin}/sadmin${url}`, obj, Header)
      .then((response) => {
        fonction(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const SearchContrats = (options) => {
    let IsInit;
    if (options.isReinit) {
      IsInit = {
        filters: {
          desk: Desk,
          // agt_supp_id: idToAdmin
        },
        filters_date: {},
        sorts: {},
        limit: "",
      };
    } else {
      IsInit = filters;
    }
    setLoaderStart(true);
    axios
      .post(
        `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
        IsInit,
        Header
      )
      .then((response) => {
        dispatch(setAllContracts(response.data.data.results));
        setLoaderStart(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoaderStart(false);
      });
  };

  const reinitFilters = () => {
    setShowBtnFilter(false);
    dispatch(
      setFilters({
        filters: {
          desk: Desk,
        },
        filters_date: {},
        sorts: {},
        limit: "",
      })
    );
    setObjToFetch({
      filters: {
        desk: Desk,
      },
      filters_date: {},
      sorts: {},
      limit: "",
    });
    setInputSearchCustomerfname("");
    setInputSearchCustomerlname("");
    setInputSearchCustomerlnamefname("");
    setDropAnnul(null);
    setDropDispo(null);
    setDropPreuve(null);
    setDropEncaisse(null);
    setDropReport(null);
    SearchContrats({ isReinit: true });
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    dispatch(setFilters(objToFetch));
    // eslint-disable-next-line
  }, [objToFetch]);

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.data.rep}
        </div>
      </components.Option>
    );
  };

  const CustomDrop = (props) => {
    return (
      <components.Option {...props}>
        <div className="d-flex justify-content-between align-items-center text-black">
          <span>{props.data.label}</span>
          <span
            style={{ color: "white" }}
            className={`badge badge-${props.data.profil}`}
          >
            {props.data.profil}
          </span>
        </div>
      </components.Option>
    );
  };

  const handleDateChange = (dates, isForEncaisse = false) => {
    const [start, end] = dates;
    if (isForEncaisse) {
      setStartDateEncaisse(start);
      setEndDateEncaisse(end);
      setStartDate(null);
      setEndDate(null);
    } else {
      setStartDate(start);
      setEndDate(end);
      setStartDateEncaisse(null);
      setEndDateEncaisse(null);
    }
    setShowBtnFilter(true);
    const formatDateToYYYYMMDD = (date) => {
      if (!date) return null;
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    let arrayToSend;
    if (start && end) {
      arrayToSend = [formatDateToYYYYMMDD(start), formatDateToYYYYMMDD(end)];
    } else if (start) {
      arrayToSend = [formatDateToYYYYMMDD(start)];
    }

    // Update the filters in your state or object to fetch data
    setObjToFetch((prevState) => {
      const { created_at, date_encaisse, ...restFiltersDate } =
        prevState.filters_date || {};
      return {
        ...prevState,
        filters_date: {
          ...restFiltersDate,
          [isForEncaisse ? "date_encaisse" : "created_at"]: arrayToSend,
        },
      };
    });
  };

  const datePickerStyle = {
    paddingRight: "30px", // Pour laisser de l'espace à droite pour une icône
    border: "1px solid #ced4da",
    borderRadius: "4px",
    height: "38px", // Ajuster la hauteur selon vos besoins
    width: "100%", // Pour s'assurer qu'il prend tout l'espace disponible
    boxSizing: "border-box", // Pour inclure le padding et la border dans la largeur totale
    paddingLeft: "10px", // Pour donner un peu d'espace à gauche aussi
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setObjToFetch((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        is_active: isChecked ? 0 : 1,
      },
    }));
  };

  const handleInputChange = (filterName, setInputValue) => (e) => {
    setShowBtnFilter(true);
    setInputValue(e.target.value);
    setObjToFetch((prevState) => {
      if (filterName === "cust_both") {
        return {
          ...prevState,
          filters: {
            ...prevState.filters,
            cust_lname: e.target.value,
            cust_fname: e.target.value,
          },
        };
      } else {
        return {
          ...prevState,
          filters: {
            ...prevState.filters,
            [filterName]: e.target.value,
          },
        };
      }
    });
  };

  const inputStyle = {
    height: "36px",
    border: "1px solid #CCCCCC",
    color: "#808080",
    fontSize: "0.9rem",
  };

  const handleSelectChange = (filterName) => (selectedOptions) => {
    setShowBtnFilter(true);
    setObjToFetch((prevState) => {
      const updatedFilters = { ...prevState.filters };
      if (selectedOptions && selectedOptions.length > 0) {
        updatedFilters[filterName] = selectedOptions.map(
          (option) => option.value
        );
      } else {
        delete updatedFilters[filterName];
      }
      return {
        ...prevState,
        filters: updatedFilters,
      };
    });
  };

  const getSelectValue = (options, filterName) => {
    return (
      options.filter(
        (option) =>
          objToFetch.filters[filterName] &&
          objToFetch.filters[filterName].includes(option.value)
      ) || undefined
    );
  };

  const handleFilterChange = (selectedOptions, prevState, filterKey) => {
    const updatedFilters = { ...prevState.filters };
    if (selectedOptions && selectedOptions.length > 0) {
      // Si des options sont sélectionnées, mettre à jour la clé avec un tableau des valeurs sélectionnées
      updatedFilters[filterKey] = selectedOptions.map((option) => option.value);
    } else {
      // Si aucune option n'est sélectionnée, supprimer la clé des filtres
      delete updatedFilters[filterKey];
    }
    return {
      ...prevState,
      filters: updatedFilters,
    };
  };

  const updateFilters = (selectedOption, dropValue, prevState) => {
    const updatedFilters = { ...prevState.filters };

    if (selectedOption) {
      // If an option is selected, update the key with selectedOption.Tofetch and value with selectedOption.checked
      updatedFilters[selectedOption.Tofetch] = selectedOption.checked;
    } else if (dropValue) {
      // If no option is selected and dropValue has a previous value, remove the key based on dropValue.Tofetch
      delete updatedFilters[dropValue.Tofetch];
    }

    return {
      ...prevState,
      filters: updatedFilters,
    };
  };

  return (
    <div className="my-2">
      {LoaderStart ? (
        <div className="sweet-loading" style={{ marginTop: "100px" }}>
          <CircleLoader
            color="#36d7b7"
            loading="true"
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <div className="mt-2 mb-2 trait-filtre"></div>
          <div className="d-flex">
            <div className="d-flex flex-column mx-2 justify-content-end">
              <label htmlFor="" className="mt-1">
                Client
              </label>

              <input
                type="text"
                value={inputSearchCustomerfname}
                onChange={handleInputChange(
                  "cust_fname",
                  setInputSearchCustomerfname
                )}
                className="rounded-1 ps-2 mb-2"
                id="input-search"
                placeholder="Prenom"
                style={inputStyle}
              />
              <input
                type="text"
                value={inputSearchCustomerlname}
                onChange={handleInputChange(
                  "cust_lname",
                  setInputSearchCustomerlname
                )}
                className="rounded-1 ps-2"
                id="input-search"
                placeholder="Nom"
                style={inputStyle}
              />
              <input
                type="text"
                value={inputSearchCustomerlnamefname}
                onChange={handleInputChange(
                  "cust_both",
                  setInputSearchCustomerlnamefname
                )}
                className="rounded-1 ps-2 mt-2"
                id="input-search"
                placeholder="Nom ou Prenom"
                style={inputStyle}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <label htmlFor="">Pseudo</label>
              <ReactSelect
                options={optionsPseudo}
                className="drop-search my-1"
                placeholder="Seller"
                value={getSelectValue(optionsPseudo, "pseudo_seller")}
                onChange={handleSelectChange("pseudo_seller")}
                components={{ Option: CustomDrop }}
                styles={styleFilters()}
                isSearchable={true}
                isClearable={true}
                isMulti={true}
              />
              <ReactSelect
                options={optionsSupport}
                className="mt-1"
                placeholder="Support"
                value={getSelectValue(optionsSupport, "pseudo_agt_supp")}
                styles={styleFilters()}
                onChange={handleSelectChange("pseudo_agt_supp")}
                components={{ Option: CustomDrop }}
                isSearchable={true}
                isClearable={true}
                isMulti={true}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <label htmlFor="">Contrat</label>
              <ReactSelect
                options={pspSelect}
                className="drop-search my-1"
                classNamePrefix="psp"
                placeholder="PSP"
                value={
                  pspSelect.filter(
                    (option) =>
                      objToFetch.filters.psp &&
                      objToFetch.filters.psp.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) => {
                  setShowBtnFilter(true);
                  setObjToFetch((prevState) =>
                    handleFilterChange(selectedOptions, prevState, "psp")
                  );
                }}
                isClearable={true}
                isMulti={true} // Permet la sélection multiple
              />
              <ReactSelect
                options={neobankSelect}
                className="mt-1"
                classNamePrefix="neobank"
                placeholder="Plateforme"
                value={
                  neobankSelect.filter(
                    (option) =>
                      objToFetch.filters.neobank &&
                      objToFetch.filters.neobank.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) => {
                  setShowBtnFilter(true);
                  setObjToFetch((prevState) =>
                    handleFilterChange(selectedOptions, prevState, "neobank")
                  );
                }}
                isClearable={true}
                isMulti={true} // Permet la sélection multiple
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <ReactSelect
                options={customerBankSelect}
                className="mb-2"
                classNamePrefix="customerBank"
                placeholder="Bank Client"
                value={
                  customerBankSelect.filter(
                    (option) =>
                      objToFetch.filters.customerbank &&
                      objToFetch.filters.customerbank.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) => {
                  setShowBtnFilter(true);
                  setObjToFetch((prevState) =>
                    handleFilterChange(
                      selectedOptions,
                      prevState,
                      "customerbank"
                    )
                  );
                }}
                isClearable={true}
                isMulti={true} // Permet la sélection multiple
              />
              <ReactSelect
                options={brandSelect}
                className=""
                classNamePrefix="Brand"
                placeholder="Brand"
                value={
                  brandSelect.filter(
                    (option) =>
                      objToFetch.filters.brand &&
                      objToFetch.filters.brand.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) => {
                  setShowBtnFilter(true);
                  setObjToFetch((prevState) =>
                    handleFilterChange(selectedOptions, prevState, "brand")
                  );
                }}
                isClearable={true}
                isMulti={true} // Permet la sélection multiple
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <label htmlFor="">Status</label>
              <ReactSelect
                options={optionsSatutsDispo}
                className="mb-2"
                classNamePrefix="status"
                placeholder="Argent dispo"
                value={dropDispo}
                styles={styleFilters()}
                onChange={(selectedOption) => {
                  setShowBtnFilter(true);
                  setDropDispo(selectedOption);
                  setObjToFetch((prevState) =>
                    updateFilters(selectedOption, dropDispo, prevState)
                  );
                }}
                components={{ Option: CustomOption }}
                isClearable={true}
              />
              <ReactSelect
                options={optionsSatutsPreuve}
                className=""
                classNamePrefix="status"
                placeholder="Preuve"
                value={dropPreuve}
                styles={styleFilters()}
                onChange={(selectedOption) => {
                  setShowBtnFilter(true);
                  setDropPreuve(selectedOption);
                  setObjToFetch((prevState) =>
                    updateFilters(selectedOption, dropPreuve, prevState)
                  );
                }}
                components={{ Option: CustomOption }}
                isClearable={true}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <ReactSelect
                options={optionsSatutsReport}
                className="mb-2"
                classNamePrefix="status"
                placeholder="Report"
                value={dropReport}
                styles={styleFilters()}
                onChange={(selectedOption) => {
                  setShowBtnFilter(true);
                  setDropReport(selectedOption);
                  setObjToFetch((prevState) =>
                    updateFilters(selectedOption, dropReport, prevState)
                  );
                }}
                components={{ Option: CustomOption }}
                isClearable={true}
              />
              <ReactSelect
                options={optionsSatutsAnnul}
                className=""
                classNamePrefix="status"
                placeholder="Annul"
                value={dropAnnul}
                styles={styleFilters()}
                onChange={(selectedOption) => {
                  setShowBtnFilter(true);
                  setDropAnnul(selectedOption);
                  setObjToFetch((prevState) =>
                    updateFilters(selectedOption, dropAnnul, prevState)
                  );
                }}
                components={{ Option: CustomOption }}
                isClearable={true}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <ReactSelect
                options={optionsSatutsEncaisse}
                className=""
                classNamePrefix="status"
                placeholder="Encaisse"
                value={dropEncaisse}
                styles={styleFilters()}
                onChange={(selectedOption) => {
                  setShowBtnFilter(true);
                  setDropEncaisse(selectedOption);
                  setObjToFetch((prevState) =>
                    updateFilters(selectedOption, dropEncaisse, prevState)
                  );
                }}
                components={{ Option: CustomOption }}
                isClearable={true}
              />
            </div>

            {teamAntoine.includes(idusers) && (
              <div className="d-flex flex-column mx-2 justify-content-end">
                <div className="mx-4 mb-1">Contrats archivés</div>
                <div className="d-flex align-items-center ms-4 border-bot-filter me-3">
                  <Form.Check
                    type="switch"
                    className="custom-switch-drop py-1"
                    style={{ transform: "scale(1)" }}
                    checked={objToFetch.filters.is_active === 0}
                    onChange={handleCheckboxChange}
                  />
                  <div className="m-0 text-archived">Non / oui</div>
                </div>
                <div className="d-flex align-items-center col-2 text-end mt-2 ">
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <DatePicker
                      selected={startDate}
                      onChange={(dates) => handleDateChange(dates, false)}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      isClearable
                      placeholderText="Dates"
                      className="input_date_filter ms-2 pointer"
                      popperClassName="custom-datepicker-popper"
                      style={datePickerStyle} // Appliquer le style à l'input
                    />
                    {SVGICON.BiCalendarIcon}
                  </div>
                </div>
                <div className="m-auto">Date d'encaissement </div>
                <div className="d-flex align-items-center col-2 text-end mt-2 ">
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <DatePicker
                      selected={startDateEncaisse}
                      onChange={(dates) => handleDateChange(dates, true)}
                      startDate={startDateEncaisse}
                      endDate={endDateEncaisse}
                      selectsRange
                      isClearable
                      placeholderText="Dates"
                      className="input_date_filter ms-2 pointer"
                      popperClassName="custom-datepicker-popper"
                      style={datePickerStyle} // Appliquer le style à l'input
                    />
                    {SVGICON.BiCalendarIcon}
                  </div>
                </div>
              </div>
            )}

          </div>
          <div className="d-flex mt-2 justify-content-end align-items-center">
            <div className="d-flex align-items-center col-3 justify-content-end">
              {showBtnFilter && (
                <Button onClick={reinitFilters} className="p-2 me-5">
                  reinit. filtres
                </Button>
              )}
              <Button
                onClick={() => SearchContrats({ isReinit: false })}
                className="p-2 px-3"
              >
                Search
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TableauCrmFilters;
