import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RadarPes from "./RadarPes";
import { getAdminDesks } from "../../Redux/slices/admin.slice";
import useHeaderRest from "../../../utils/useHeader";
import axios from "axios";
import config from "../../../config/config";
import TerrainFoot from "../../../images/terrainfc25.webp";
import fondPerfo from "../../../images/fondpes1.webp";
import cardfifa from "../../../images/badgefifa.png";
import cardfifaor from "../../../images/cardfifaOr.webp";
import cardfifaorange from "../../../images/cardfifaorange.webp";
import cardfifablancbleu from "../../../images/cardfifablancbleu.webp";
import cardfifafluo from "../../../images/cardfifafluo.webp";
import cardfifatech from "../../../images/cardfifatech.webp";
import carfifa4e from "../../../images/carfifa4e.webp";
import cardfifa1er from "../../../images/cardfifa1er.webp";
import cardfifa2 from "../../../images/cardfifa2.webp";
import { data } from "jquery";
// import cardfifa2 from "../../../images/cardfifa2.webp";
import formatNumber from "../../../utils/formatNumber";
import Badge from "./Badge";
import RankDesk from "./RankDesk";
import RankDeskDay from "./RankDeskDay";
import RankSellerDayAgent from "./RankSellerDayAgent";
import Mutliplex from "../../../son/Multiplex-Canal.mp3";
import Travis from "../../../son/Travis Scott - BUTTERFLY EFFECT (mp3cut.net).mp3";
import {
  getSockets,
  getListenAllDesks,
} from "../../Redux/slices/notifSocket.slice";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import GoalAnimation from "./GoalAnimation";

const PagesPesManager = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const useHeader = useHeaderRest();

  const GET_ADMIN_DESKS = useSelector(getAdminDesks);
  const Sockets = useSelector(getSockets);
  console.log(Sockets);
  const { desk } = useParams();
  const deskIdAsNumber = parseInt(desk, 10);

  const [combinedData, setCombinedData] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [personneHover, setPersonneHover] = useState(null);
  const [goodDesk, setGoodDesk] = useState();
  const [loading, setLoading] = useState(true); // État pour le loader
  const [dataToShow, setDataToShow] = useState({});
  const [dataSeller, setDataSeller] = useState([]); // Stocke les données actuelles
  const [animAscenceurSeller, setAnimAscenceurSeller] = useState(false); // Stocke les données actuelles
  const [differences, setDifferences] = useState([]); // Stocke les données actuelles

  const [showCompare, setShowCompare] = useState(false);

  const multiplexRef = useRef(null);
  const travisRef = useRef(null);

  const adminDetails = useSelector(getAdminDetails);
  const GETALLSOCKETS = useSelector(getListenAllDesks);
  const gooddesk = desk ? [desk] : adminDetails.desk;
  console.log(GETALLSOCKETS);
  useEffect(() => {
    // console.log("ici" )
    const playMultiplexAndFetch = () => {
      const multiplexAudio = multiplexRef.current;
      if (multiplexAudio) {
        multiplexAudio.play();
        const timer = setTimeout(() => {
          multiplexAudio.pause();
          // getDataRanking();
        }, 3000);
        const timerData = setTimeout(() => {
          // multiplexAudio.pause();
          // getDataRanking();
          getDataRankingSeller();
        }, 2000);

        return () => {
          clearTimeout(timerData);
          clearTimeout(timer);
        };
      } else {
        // getDataRanking();
        getDataRankingSeller();
      }
    };

    playMultiplexAndFetch();
  }, [GETALLSOCKETS]);

  const getDataRankingSeller = () => {
    // console.log("GETALLSOCKETS", GETALLSOCKETS.length);
    const obj = {
      desk: gooddesk,
      oldData: dataSeller, // Passe les anciennes données
    };
    // console.log(obj);
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/get_data_ranking_fifa_agent`,
        obj,
        useHeader
      )
      .then((response) => {
        // console.log(response.data)
        const newRankingDataSeller = response.data.data.sort(
          (a, b) => b.total_amount_today - a.total_amount_today
        );

        if (
          response.data.differences !== "new" &&
          response.data.differences.length > 0
        ) {
          // console.log("diife");
          // alert("boom")
          // console.log(
          //   "response.data.differences[0].desk",
          //   response.data.differences[0].desk
          // );
          setDifferences(response.data.differences);

          setTimeout(() => {
            setDifferences([]);
            //  setData(newRankingData);
            // console.log("je fonctionne");
          }, 21000);

          setTimeout(() => {
            setAnimAscenceurSeller(true);
          }, 21000);
          setTimeout(() => {
            setAnimAscenceurSeller(false);
          }, 25000);

          // audioRef.current.play();
        }
        // Mettre à jour les données actuelles et anciennes
        // console.log(newRankingDataSeller)
        setDataSeller(newRankingDataSeller);
        // console.log(newRankingData);
      })
      .catch((error) => {
        console.error("Erreur lors de la requête :", error);
      });
  };

  const DataMonthlyContracts = () => {
    return axios.post(
      `${config.apiAdmin}/statsNode/manager/card_monthly_contracts`,
      { desk: goodDesk },
      useHeader
    );
  };

  const AverageRetap = () => {
    return axios.post(
      `${config.apiAdmin}/statsNode/manager/graph_average_retap_fifa`,
      { desk: goodDesk },
      useHeader
    );
  };

  const GraphFifa = () => {
    return axios.post(
      `${config.apiAdmin}/statsNode/manager/graph_fifa`,
      { desk: goodDesk },
      useHeader
    );
  };

  useEffect(() => {
    setGoodDesk(deskIdAsNumber ? [deskIdAsNumber] : GET_ADMIN_DESKS);
  }, [desk, GET_ADMIN_DESKS, deskIdAsNumber]);

  useEffect(() => {
    if (goodDesk) {
      Promise.all([DataMonthlyContracts(), AverageRetap(), GraphFifa()])
        .then((responses) => {
          console.log("API Responses:", responses); // Vérifiez les données reçues

          // Extraire les données de chaque réponse
          const dataMonthlyContractsResponse = responses[0].data;
          const averageRetapResponse = responses[1].data;
          const graphfifaResponse = responses[2].data;

          // Vérifier si les réponses contiennent des données
          const dataMonthlyContracts =
            dataMonthlyContractsResponse.message !== "message : no-data"
              ? dataMonthlyContractsResponse.data
              : [];
          const averageRetap =
            averageRetapResponse.message !== "message : no-data"
              ? averageRetapResponse.data
              : [];
          const graphfifa =
            graphfifaResponse.message !== "message : no-data"
              ? graphfifaResponse.data
              : [];

          // Créer des maps pour une recherche rapide par pseudo
          const monthlyMap = dataMonthlyContracts.reduce((acc, item) => {
            acc[item.pseudo] = item;
            return acc;
          }, {});

          const retapMap = averageRetap.reduce((acc, item) => {
            acc[item.pseudo] = item;
            return acc;
          }, {});

          // Fusionner les données de graphfifa avec les autres datasets basé sur pseudo
          const mergedData = graphfifa
            .sort((a, b) => b.note - a.note)
            .map((item) => {
              const monthlyData = monthlyMap[item.pseudo] || {};
              const retapData = retapMap[item.pseudo] || {};

              const noteContracts = Number(monthlyData.noteContracts) || 0;
              const mote_retap = Number(retapData.mote_retap) || 0;
              const note = Number(item.note) || 0;

              // Calculer notefinal
              const notefinal = noteContracts + mote_retap + note;

              return {
                ...item,
                ...monthlyData,
                ...retapData,
                notefinal,
              };
            });

          console.log(mergedData);

          // Mettre à jour l'état avec les données fusionnées

          // console.log(mergedData)
          setDataToShow(mergedData.sort((a, b) => b.notefinal - a.notefinal));
          setLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setDataToShow([]);
          } else {
            console.error("Erreur lors de la récupération des données :", error);
          }
          setLoading(false);
        });
    }
  }, [goodDesk]);

  useEffect(() => {
    if (combinedData && Object.keys(combinedData).length > 0) {
      const transformedData = Object.entries(combinedData).map(
        ([pseudo, items]) => {
          const conversionTableau = Number(
            items.find((item) => item.conversion_count)?.conversion_count || 0
          );
          const conversionGraph = Number(
            items.find((item) => item.percentage_of_max_conversion)
              ?.percentage_of_max_conversion || 0
          );

          const contratTable = Number(
            items.find((item) => item.total_contracts)?.total_contracts || 0
          );
          const contratGraph = Number(
            items.find((item) => item.noteContracts)?.noteContracts || 7
          );

          const retapTable = Number(
            items.find((item) => item.avg_days_between_contracts)
              ?.avg_days_between_contracts || 5
          );
          const retapGraph = Number(
            items.find((item) => item.mote_retap)?.mote_retap || 5
          );

          const totalTable = Number(
            items.find((item) => item.total_encash_usd)?.total_encash_usd || 10
          );
          const totalGraph = Number(
            items.find((item) => item.percentage_of_max_total)
              ?.percentage_of_max_total || 10
          );

          const moyenneTable = Number(
            items.find((item) => item.average_encash_usd)?.average_encash_usd ||
              7
          );
          const moyenneGraph = Number(
            items.find((item) => item.percentage_of_max_average)
              ?.percentage_of_max_average || 7
          );

          const maxTable = Number(
            items.find((item) => item.max_encash_usd)?.max_encash_usd || 5
          );
          const maxGraph = Number(
            items.find((item) => item.percentage_of_max_encash)
              ?.percentage_of_max_encash || 5
          );
          const desk = Number(items.find((item) => item.desk)?.desk || 0);

          const note = Number(items.find((item) => item.note)?.note || 20);

          const noteFinal = note + retapGraph + contratGraph;
          // console.log(pseudo)

          const avatar = Number(
            items.find((item) => item.avatar)?.avatar || 20
          );

          const transformedItem = {
            name: pseudo,

            conversionTableau,
            conversionGraph,
            contratTable,
            contratGraph,
            retapTable,
            retapGraph,
            totalTable,
            totalGraph,
            moyenneTable,
            moyenneGraph,
            maxTable,
            maxGraph,
            desk,
            // avatar,
            avatar: items.find((item) => item.avatar_url)?.avatar_url || avatar,
            // note: Math.round(Note), // Ajouter la clé Note avec la moyenne des statistiques
            note: noteFinal,
          };
          // console.log(transformedItem);
          return transformedItem;
        }
      );
      // setSelectedPerson(transformedData[0]);
      transformedData.sort((a, b) => b.notefinal - a.notefinal);
      // console.log(transformedData.sort((a, b) => b.note - a.note))
      // setSelectedPerson(transformedData[0]);
      setDataToShow(transformedData);

      setLoading(false); // Désactiver le loader après la transformation des données
    }
  }, [combinedData]);

  useEffect(() => {
    const travisAudio = travisRef.current;
    let timer;

    if (travisAudio) {
      travisAudio.play();
      timer = setTimeout(() => {
        travisAudio.pause();
      }, 21000);
    }

    return () => {
      if (timer) clearTimeout(timer);
      if (travisAudio) travisAudio.pause();
    };
  }, [differences]);

  if (loading) {
    return (
      <div className="bg-dark d-flex justify-content-center align-items-center vh-100">
        <div
          className="spinner-border text-light"
          role="status"
          style={{ width: "5rem", height: "5rem" }}
        >
          {/* <span className="sr-only">Loading...</span> */}
        </div>
      </div>
    ); // Afficher le loader pendant le chargement
  }

  return (
    <>
      {/* <audio ref={travisRef} src={Travis} /> */}
      <audio ref={multiplexRef} src={Mutliplex} />

      {differences.length > 0 && (
        <>
          <GoalAnimation differences={differences} />
          {/* <audio ref={multiplexRef} src={Mutliplex} /> */}
          <audio ref={travisRef} src={Travis} />
        </>
      )}
      <>
        <div
          className="d-flex justify-content-center align-items-start"
          //  style={{ backgroundImage: `url(${fondPerfo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          style={{ height: "69vh", width: "100%" }}
        >
          <div
            className="rounded position-relative"
            style={{ height: "100%", width: "100%" }}
          >
            <img
              src={TerrainFoot}
              alt="Terrain de foot"
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0.8,
                // borderRadius: "10px",
              }}
            />
            <div>
              <div>
                {dataToShow?.slice(0, 5).map((item, index) => {
                  const positions = [
                    { top: "20%", left: "50%" },
                    { top: "40%", left: "30%" },
                    { top: "40%", left: "70%" },
                    { top: "70%", left: "40%" },
                    { top: "70%", left: "60%" },
                  ];
                  const positionsAvec = [
                    { top: "20%", left: "35%" },
                    { top: "40%", left: "20%" },
                    { top: "40%", left: "50%" },
                    { top: "70%", left: "27%" },
                    { top: "70%", left: "42%" },
                  ];
                  return (
                    <div
                      key={item.seller_id}
                      className={`position-absolute d-flex flex-column align-items-center justify-content-center rounded p-2 px-4 ${
                        item.seller_id === selectedPerson?.seller_id
                          ? "pes-selected-seller"
                          : ""
                      } ${
                        item.seller_id === personneHover?.seller_id
                          ? "pes-hover-seller"
                          : ""
                      }`}
                      style={{
                        ...(showCompare
                          ? positionsAvec[index]
                          : positions[index]),
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                        backgroundImage: `url(${
                          index === 0
                            ? cardfifa1er
                            : index === 1
                            ? cardfifa2
                            : index === 2
                            ? cardfifatech
                            : index === 3
                            ? carfifa4e
                            : index === 4
                            ? cardfifafluo
                            : cardfifa
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "200px",
                        width: "150px",
                      }}
                      onClick={() => {
                        setSelectedPerson(item);
                        setShowCompare(true);
                      }}
                      onMouseEnter={() => setPersonneHover(item)}
                      onMouseLeave={() => setPersonneHover(null)}
                    >
                      {/* Badge en haut à droite */}
                      <div
                        className={`position-absolute top-0 start-0 ${
                          index === 3 || index === 4 ? "mt-4" : ""
                        }`}
                        style={{
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transform: "translate(50%, -50%)",
                        }}
                      >
                        <Badge index={index} list={false} rounded={false} />
                      </div>
                      <div
                        className={`position-absolute top-0 start-50 ${
                          index === 3 || index === 4 ? "mt-4" : ""
                        }`}
                        style={{
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transform: "translate(50%, -50%)",
                        }}
                      >
                        <Badge
                          note={item?.notefinal}
                          list={false}
                          rounded={true}
                        />
                      </div>

                      {item.avatar_url === 0 ||
                      item.avatar_url === "default_avatar_url" ? (
                        <div
                          className="mt-1 mb-1"
                          style={{
                            height: "80px",
                            width: "80px",
                            borderRadius: "50%",
                            backgroundColor: "#dae0e5",
                          }}
                        />
                      ) : (
                        <img
                          className="mt-1 mb-1"
                          src={item?.avatar_url}
                          alt={item?.pseudo}
                          style={{ height: "80px", borderRadius: "50%" }}
                        />
                      )}
                      <div className="col-12 p-0">
                        <p
                          className={`text-center p-0 mb-1 ${
                            index === 0
                              ? "text-card-1"
                              : index === 1
                              ? "text-card-2"
                              : index === 2
                              ? "text-card-3"
                              : index === 3
                              ? "text-card-4"
                              : index === 4
                              ? "text-card-5"
                              : "text-white"
                          } ${
                            index === 0
                              ? "p-fifa-1"
                              : index === 1
                              ? "p-fifa-2"
                              : index === 2
                              ? "p-fifa-3"
                              : index === 3
                              ? "p-fifa-4"
                              : index === 4
                              ? "p-fifa-5"
                              : "border-bottom"
                          }`}
                        >
                          {item?.pseudo}
                        </p>
                        <div className={`d-flex justify-content-between pb-1`}>
                          <div
                            className={`col-6 d-flex justify-content-center ${
                              index === 0
                                ? "text-card-1"
                                : index === 1
                                ? "text-card-2"
                                : index === 2
                                ? "text-card-3"
                                : index === 3
                                ? "text-card-4"
                                : index === 4
                                ? "text-card-5"
                                : "text-white"
                            } ${
                              index === 0
                                ? "border-right-1"
                                : index === 1
                                ? "border-right-2"
                                : index === 2
                                ? "border-right-3"
                                : index === 3
                                ? "border-right-4"
                                : index === 4
                                ? "border-right-5"
                                : ""
                            }`}
                          >
                            <div className="me-1">{item?.conversion_count}</div>
                            <div className="ms-1 fw-normal">CV</div>
                          </div>
                          <div
                            className={`col-6 d-flex justify-content-center  ${
                              index === 0
                                ? "text-card-1"
                                : index === 1
                                ? "text-card-2"
                                : index === 2
                                ? "text-card-3"
                                : index === 3
                                ? "text-card-4"
                                : index === 4
                                ? "text-card-5"
                                : "text-white"
                            }`}
                          >
                            <div className="me-1">{item?.total_contracts}</div>
                            <div className="ms-1 fw-normal">CR</div>
                          </div>
                        </div>
                        <p
                          className={`text-center p-0 m-0 ${
                            index === 0
                              ? "text-card-1"
                              : index === 1
                              ? "text-card-2"
                              : index === 2
                              ? "text-card-3"
                              : index === 3
                              ? "text-card-4"
                              : index === 4
                              ? "text-card-5"
                              : "text-white"
                          } ${
                            index === 0
                              ? "m-fifa-1"
                              : index === 1
                              ? "m-fifa-2"
                              : index === 2
                              ? "m-fifa-3"
                              : index === 3
                              ? "m-fifa-4"
                              : index === 4
                              ? "m-fifa-5"
                              : "border-top"
                          }`}
                        >
                          $ {formatNumber(item?.total_encash_usd)}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <div>
                  <div
                    className="position-absolute"
                    style={{ height: "100%", width: "20%", left: "0px" }}
                  >
                    <RankSellerDayAgent
                      dataSeller={dataSeller}
                      animAscenceurSeller={animAscenceurSeller}
                    />
                  </div>
                </div>
              </div>
              {showCompare && (
                <div
                  className="position-absolute animate-slide-in"
                  style={{
                    left: "55%",
                    transform: "translateY(0%)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowCompare(false);
                    setSelectedPerson(null);
                  }}
                >
                  <h5 className="text-center bg-transparent text-white border rounded-5 px-2 mt-2">
                    Fermer
                  </h5>
                </div>
              )}
              {/* <div>
              <div
                className="position-absolute"
                style={{ height: "100%", width: "20%" }}
              >
                <RankDeskDay />
              </div>
            </div>
            <div>
              <div
                className="position-absolute"
                style={{ height: "100%", width: "20%", right:"0px" }}
              >
                <RankSellerDay />
              </div>
            </div> */}
              {showCompare && (
                <div
                  className="position-absolute h-100"
                  style={{
                    top: "50%",
                    right: "0px",
                    width: "600px",
                    transform: "translateY(-50%)",
                  }}
                >
                  <div
                    className="animate-slide-in"
                    style={{
                      height: "100%",
                      width: "100%",
                      background:
                        "linear-gradient(to right, rgba(0, 0, 0, 0.005), rgba(0, 0, 0, 0.7))",
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center h-50 col-12">
                      <div className="col-12 h-100 d-flex align-items-center justify-content-center">
                        <RadarPes
                          personneSel={selectedPerson}
                          personneHover={personneHover}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center h-50 col-12 fs-16">
                      <div className="col-6 p-0 mb-5">
                        <div className="text-end me-2 fs-24 fw-bold text-sel-fifa text-capitalize">
                          {selectedPerson?.pseudo}
                        </div>
                        <div className="d-flex align-items-center justify-content-center mb-3">
                          {selectedPerson?.avatar_url === 0 ? (
                            <div
                              style={{
                                height: "60px",
                                width: "60px",
                                borderRadius: "50%",
                                backgroundColor: "#dae0e5",
                              }}
                            />
                          ) : (
                            <img
                              src={selectedPerson?.avatar_url}
                              alt={selectedPerson?.pseudo}
                              style={{ height: "60px" }}
                            />
                          )}
                          <h3 className="text-center fw-bold text-white">
                            {selectedPerson?.notefinal}
                          </h3>
                        </div>
                        <ul className="list-pes text-white">
                          <li>
                            <div>Conversion:</div>
                            <div
                              className={
                                !personneHover ||
                                selectedPerson?.conversion_count ===
                                  personneHover?.conversion_count
                                  ? "text-white"
                                  : selectedPerson?.conversion_count >
                                    personneHover?.conversion_count
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {selectedPerson?.conversion_count}
                            </div>
                          </li>
                          <li>
                            <div>Nb contrats: </div>
                            <div
                              className={
                                !personneHover ||
                                selectedPerson?.total_contracts ===
                                  personneHover?.total_contracts
                                  ? "text-white"
                                  : selectedPerson?.total_contracts >
                                    personneHover?.total_contracts
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {selectedPerson?.total_contracts}
                            </div>
                          </li>
                          <li>
                            <div>Retap:</div>
                            <div
                              className={
                                !personneHover ||
                                selectedPerson?.avg_days_between_contracts ===
                                  personneHover?.avg_days_between_contracts
                                  ? "text-white"
                                  : selectedPerson?.avg_days_between_contracts <
                                    personneHover?.avg_days_between_contracts
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {selectedPerson?.avg_days_between_contracts} j
                            </div>
                          </li>
                          <li>
                            <div>Encaissement:</div>
                            <div
                              className={
                                !personneHover ||
                                selectedPerson?.total_encash_usd ===
                                  personneHover?.total_encash_usd
                                  ? "text-white"
                                  : selectedPerson?.total_encash_usd >
                                    personneHover?.total_encash_usd
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {formatNumber(selectedPerson?.total_encash_usd)} $
                            </div>
                          </li>
                          <li>
                            <div>Encaissement le plus haut:</div>
                            <div
                              className={
                                !personneHover ||
                                selectedPerson?.max_encash_usd ===
                                  personneHover?.max_encash_usd
                                  ? "text-white"
                                  : selectedPerson?.max_encash_usd >
                                    personneHover?.max_encash_usd
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {formatNumber(selectedPerson?.max_encash_usd)} $
                            </div>
                          </li>
                          <li>
                            <div>Moyenne encaissement:</div>
                            <div
                              className={
                                !personneHover ||
                                selectedPerson?.average_encash_usd ===
                                  personneHover?.average_encash_usd
                                  ? "text-white"
                                  : selectedPerson?.average_encash_usd >
                                    personneHover?.average_encash_usd
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {formatNumber(selectedPerson?.average_encash_usd)}{" "}
                              $
                            </div>
                          </li>
                        </ul>
                      </div>
                      {personneHover && (
                        <div className="col-6 p-0 mb-5">
                          <div className="text-end me-2 fs-24 fw-bold text-hover-fifa text-capitalize">
                            {personneHover?.pseudo}
                          </div>
                          <div className="d-flex align-items-center justify-content-center mb-3 text-white">
                            {personneHover.avatar_url === 0 ||
                            personneHover.avatar_url ===
                              "default_avatar_url" ? (
                              <div
                                style={{
                                  height: "60px",
                                  width: "60px",
                                  borderRadius: "50%",
                                  backgroundColor: "#dae0e5",
                                }}
                              />
                            ) : (
                              <img
                                src={personneHover?.avatar_url}
                                alt={personneHover?.pseudo}
                                style={{ height: "60px" }}
                              />
                            )}
                            <h3 className="text-center fw-bold text-white">
                              {personneHover?.notefinal}
                            </h3>
                          </div>
                          <ul className="list-pes-hover text-white text-pes-sel-2">
                            <li>
                              <div>Conversion:</div>
                              <div
                                className={
                                  !selectedPerson ||
                                  personneHover?.conversion_count ===
                                    selectedPerson?.conversion_count
                                    ? "text-white"
                                    : personneHover?.conversion_count >
                                      selectedPerson?.conversion_count
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {personneHover?.conversion_count}
                              </div>
                            </li>
                            <li>
                              <div>Nb contrats: </div>
                              <div
                                className={
                                  !selectedPerson ||
                                  personneHover?.total_contracts ===
                                    selectedPerson?.total_contracts
                                    ? "text-white"
                                    : personneHover?.total_contracts >
                                      selectedPerson?.total_contracts
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {personneHover?.total_contracts}
                              </div>
                            </li>
                            <li>
                              <div>Retap j:</div>
                              <div
                                className={
                                  !selectedPerson ||
                                  personneHover?.avg_days_between_contracts ===
                                    selectedPerson?.avg_days_between_contracts
                                    ? "text-white"
                                    : personneHover?.avg_days_between_contracts <
                                      selectedPerson?.avg_days_between_contracts
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {personneHover?.avg_days_between_contracts} j
                              </div>
                            </li>
                            <li>
                              <div>Encaissement:</div>
                              <div
                                className={
                                  !selectedPerson ||
                                  personneHover?.total_encash_usd ===
                                    selectedPerson?.total_encash_usd
                                    ? "text-white"
                                    : personneHover?.total_encash_usd >
                                      selectedPerson?.total_encash_usd
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {formatNumber(personneHover?.total_encash_usd)}{" "}
                                $
                              </div>
                            </li>
                            {/* <li>
                            <div>NumContract:</div>
                            <div
                              className={
                                !selectedPerson ||
                                personneHover?.NumContract ===
                                  selectedPerson?.NumContract
                                  ? "text-white"
                                  : personneHover?.NumContract >
                                    selectedPerson?.NumContract
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {personneHover?.NumContract}
                            </div>
                          </li> */}
                            <li>
                              <div>Encaissement le plus haut:</div>
                              <div
                                className={
                                  !selectedPerson ||
                                  personneHover?.max_encash_usd ===
                                    selectedPerson?.max_encash_usd
                                    ? "text-white"
                                    : personneHover?.max_encash_usd >
                                      selectedPerson?.max_encash_usd
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {formatNumber(personneHover?.max_encash_usd)} $
                              </div>
                            </li>
                            <li>
                              <div>Moyenne encaissement:</div>
                              <div
                                className={
                                  !selectedPerson ||
                                  personneHover?.average_encash_usd ===
                                    selectedPerson?.average_encash_usd
                                    ? "text-white"
                                    : personneHover?.average_encash_usd >
                                      selectedPerson?.average_encash_usd
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {formatNumber(
                                  personneHover?.average_encash_usd
                                )}{" "}
                                $
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <div
            className="h-25 card-container bg-list-remplacent"
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "flex-start", // Aligner les cartes au début
              overflowX: "auto", // Scroll horizontal si nécessaire
              padding: "10px", // Marges internes autour des cartes
              minHeight: "125px",
              scrollPaddingLeft: "10px", // Ajoute un padding lors du scroll
              whiteSpace: "nowrap", // Empêche le contenu de se briser sur plusieurs lignes
            }}
          >
            {Array.isArray(dataToShow) &&
              dataToShow.slice(5, 100).map((card, index) => (
                <React.Fragment key={index}>
                  {index === 0 && (
                    <div>
                      <h4 className="text-vertical-fifa m-0 text-white text-uppercase">
                        Remplacents
                      </h4>
                    </div>
                  )}
                  {index === 5 && (
                    <div>
                      <h4 className="text-vertical-fifa m-0 text-white text-uppercase">
                        Reserve
                      </h4>
                    </div>
                  )}
                  <div
                    className={`d-flex flex-column align-items-center justify-content-around position-relative ${
                      card.seller_id === selectedPerson?.seller_id ? "border-hover" : ""
                    } ${
                      card.seller_id === personneHover?.seller_id ? "border-selected" : ""
                    }`}
                    style={{
                      cursor: "pointer",
                      backgroundImage: `url(${
                        index >= 0 && index <= 4
                          ? cardfifaorange
                          : cardfifablancbleu
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "150px", // Height of the card
                      minWidth: "120px", // Width of the card
                      marginRight: "10px", // Spacing between the cards
                      flexShrink: 0.8, // Prevents cards from shrinking
                    }}
                    onClick={() => {
                      setSelectedPerson(card);
                      setShowCompare(true);
                    }}
                    onMouseEnter={() => setPersonneHover(card)}
                    onMouseLeave={() => setPersonneHover(null)}
                  >
                    <div
                      className="card-container-fifa"
                      style={{ position: "relative" }}
                    >
                      <div className="badge-top-right">
                        <Badge index={index} list={true} />
                      </div>
                      <div
                        className="position-absolute top-0 start-50"
                        style={{
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transform: "translate(50%, -50%)",
                          paddingRight: "15px",
                          paddingTop: "5px",
                        }}
                      >
                        <Badge
                          note={card?.notefinal}
                          list={false}
                          rounded={true}
                        />
                      </div>

                      {card.avatar_url === 0 ||
                      card.avatar_url === "default_avatar_url" ? (
                        <div
                          style={{
                            height: "60px",
                            width: "60px",
                            borderRadius: "50%",
                            backgroundColor: "#dae0e5",
                          }}
                        />
                      ) : (
                        <img
                          src={card?.avatar_url}
                          alt={card?.pseudo}
                          style={{ height: "60px", borderRadius: "50%" }}
                        />
                      )}
                    </div>
                    <div className="col-8 p-0">
                      <p
                        className={`text-center p-fifa p-0 mb-1 ${
                          index <= 4
                            ? "text-card-remplacent"
                            : "text-card-reserve"
                        }`}
                      >
                        {card?.pseudo}
                      </p>
                      <div className="d-flex justify-content-between m-fifa pb-1">
                        <div
                          className={`col-6 d-flex justify-content-center border-right ${
                            index <= 4
                              ? "text-card-remplacent"
                              : "text-card-reserve"
                          }`}
                        >
                          <div className="">{card?.conversion_count}</div>
                          <div className="ms-1 fw-normal">CV</div>
                        </div>
                        <div
                          className={`col-6 d-flex justify-content-center ${
                            index <= 4
                              ? "text-card-remplacent"
                              : "text-card-reserve"
                          }`}
                        >
                          <div className="">{card?.total_contracts}</div>
                          <div className="ms-1 fw-normal">CR</div>
                        </div>
                      </div>
                      <p
                        className={`text-center p-0 m-0 mb-2 ${
                          index <= 4
                            ? "text-card-remplacent"
                            : "text-card-reserve"
                        }`}
                      >
                        $ {formatNumber(card?.total_encash_usd)}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </>
    </>
  );
};

export default PagesPesManager;
