import { createSlice } from "@reduxjs/toolkit";
import { set } from "react-hook-form";

export const actionsReducer = createSlice({
  name: "actions",
  initialState: {
    authentified: false,
    deskNew: false,
    adminNew: false,
    popup: {
      message: "",
      info: "",
      isActive: false
    },
    deskSideBar: "",
    interdit: false, 
    goalAnimation: {desk: null, avatars: null, show: false},
    butAnimate: {desk: null, avatars: null, show: false },
    showRankSeller: false,
    allLeads:[] ,
    allFavorit: []
  },
  reducers: {
    setDeskNew: (state, { payload }) => {
      state.deskNew = payload;
    },
    setAdminNew: (state, { payload }) => {
      state.adminNew = payload;
    },    
    setAllLeads: (state, { payload }) => {
      state.allLeads = payload;
    },
    setPopup: (state, { payload }) => {
      // Définir message, info, et isActive à true
      state.popup = {
        message: payload.message,
        info: payload.info,
        isActive: payload.isActive
      };
    },
    setButAnimate : (state, { payload }) => {
      // Définir message, info, et isActive à true
      state.popup = {
        desk: payload.desk,
        avatars: payload.avatars,
        show: payload.show
      };
    },
    setDeskSidebar: (state, { payload }) => {
      state.deskSideBar = payload;
    },
    setInterdit: (state, { payload }) => {
      // console.log("payload", payload) 
      state.interdit = payload;
    },
    setGoalAnimation: (state, { payload }) => {
      // console.log("payload", payload) 
      state.goalAnimation = payload;
    },
    setShowRankSeller: (state, { payload }) => {
      // console.log("payload", payload)
      state.showRankSeller = payload; 
    },
    setAllFavorit: (state, {payload}) => {
      state.allFavorit = payload
    }

  },
});

export const { setDeskNew, setAdminNew, setPopup, setDeskSidebar, setInterdit, setGoalAnimation, setButAnimate, setShowRankSeller, setAllLeads, setAllFavorit } = actionsReducer.actions;

export const getDeskNew = (state) => {
  // console.log(state.actions.deskNew)
  return state.actions.deskNew;
};

export const getAllLeads = (state) => {
  // console.log(state.actions.deskNew)
  return state.actions.allLeads;
};

export const getAdminNew = (state) => {
  // console.log(state.actions.adminNew)
  return state.actions.adminNew;
};

export const getPopup = (state) => {
  // console.log(state.actions.popup)
  return state.actions.popup;
};

export const getDeskSidebar = (state) => {
  return state.actions.deskSideBar;
}

export const getInterdit = (state) => {
  // console.log(state)
  return state.actions.interdit;
}

export const getGoalAnimation = (state) => {
  return state.actions.goalAnimation;
}
export const getButAnimate = (state) => {
  return state.actions.butAnimate;
}
export const getButAnimateShow = (state) => {
  return state.actions.butAnimate.show;
}
export const getShowRankSeller = (state) => {
  return state.actions.showRankSeller;
}
export const getAllFavorit = (state) => {
  return state.actions.allFavorit
}


export default actionsReducer.reducer;
