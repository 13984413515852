import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
/// React router dom
import { Routes, Route, Outlet, useLocation } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
// import ScrollToTop from "./layouts/ScrollToTop";

import Login from "../jsx/pages/Login";
import Notifs from "../PagesCrm/Global/Notifs";
import { gql, useSubscription } from "@apollo/client";

//////////////////////////////////SUPERADMIN


//////////////////////////////////TEAM LEADER
import DashboardTl from "../PagesCrm/TeamLeader/PageStatistiques";
import TableauTl from "../PagesCrm/TeamLeader/PageTableau";
import StatsTl from "../PagesCrm/TeamLeader/PageDashboard";
import ParamsTl from "../PagesCrm/Global/Users/PagesUsers";
import TargetsTl from "../PagesCrm/Global/Targets/Targets";
import AlertsTl from "../PagesCrm/TeamLeader/Configurations/Alerts";

//////////////////////////////////Admin
import DashboardAdmin from "../PagesCrm/Admin/PageStatistiques";
import TableauAdmin from "../PagesCrm/Admin/PageTableau";
import ParamsAdmin from "../PagesCrm/Global/Users/PagesUsers";
import StatsAdmin from "../PagesCrm/Admin/PageDashboard";
import TargetsAdmin from "../PagesCrm/Global/Targets/Targets";
import AlertsAdmin from "../PagesCrm/Admin/Configurations/Alerts";
import PspAdmin from "../PagesCrm/Admin/Configurations/Psp";
import NeobankAdmin from "../PagesCrm/Admin/Configurations/Neobank";
import BankClientAdmin from "../PagesCrm/Admin/Configurations/BankClient";
import BrandAdmin from "../PagesCrm/Admin/Configurations/Brand";
import DeskAdmin from "../PagesCrm/Admin/Configurations/Desk";

import DeskDetailAdmin from "../PagesCrm/Admin/DeskDetail";
// import DeskDetailAdmin from "../PagesCrm/Admin/DeskDetail";

//////////////////////////////////MANAGER
import DashboardM from "../PagesCrm/Manager/PageStatistiques";
import TableauM from "../PagesCrm/Manager/PageTableau";
import ParamsM from "../PagesCrm/Global/Users/PagesUsers";
import StatsM from "../PagesCrm/Manager/PageDashboard";
// import TargetsM from "../PagesCrm/Manager/Configurations/Targets";
import TargetsM from "../PagesCrm/Global/Targets/Targets";
import AlertsM from "../PagesCrm/Manager/Configurations/Alerts";
import PagesPes from "../PagesCrm/Global/PagePes/PagesPes";
import PagesDoc from "../PagesCrm/Components/Docs/PageDocDirigeantKo";
import PagesPesMonthlyManager
 from "../PagesCrm/Global/PagePes/PagesPesMonthlyManager";
 import PagesLeads from '../PagesCrm/Global/Leads/PageLeads'
 import ChatAI from "../PagesCrm/Global/ChatAI/ChatAI";
 

//////////////////////////////////SECRETAIRE
// import TestMomo from "./components/Dashboard/Test_momo";
// import TestMomoTab from "./components/Dashboard/Test_momo_tab";
// import TestMomoParams from "./components/Dashboard/Test_momo_params";
// import TestMomoDroits from "./components/Dashboard/Test_momo_droits";

//////////////////////////////////CHEFSUPPORT
import DashboardCS from "../PagesCrm/BossSupport/PageStats";
import TableauCS from "../PagesCrm/BossSupport/PageTableau";
import CardsCS from "../PagesCrm/BossSupport/PageDashboard";
import ParamsCS from "../PagesCrm/Global/Users/UsersSupport/PagesUsersSupport";
import AlertsCS from "../PagesCrm/BossSupport/Configurations/Alerts";
import PspCS from "../PagesCrm/BossSupport/Configurations/Psp";
import NeobankCS from "../PagesCrm/BossSupport/Configurations/Neobank";
import BankClientCS from "../PagesCrm/BossSupport/Configurations/BankClient";
import BrandCS from "../PagesCrm/BossSupport/Configurations/Brand";
import DeskCS from "../PagesCrm/BossSupport/Configurations/Desk";

//////////////////////////////////MANAGERSUPPORT
import DashboardMS from "../PagesCrm/MngSupport/PageStatistiques";
import TableauMS from "../PagesCrm/MngSupport/PageTableau";
import CardsMS from "../PagesCrm/MngSupport/PageDashBoard";
import ParamsMS from "../PagesCrm/MngSupport/Configurations/Params";
import AlertsMS from "../PagesCrm/MngSupport/Configurations/Alerts";

//////////////////////////////////SELLER
import DashboardSell from "../PagesCrm/Seller/Dashboard";
import TableauSell from "../PagesCrm/Seller/Tableau";
import DocumentFindBoard from "../PagesCrm/Components/Docs/PageDocFindBoard";

//////////////////////////////////SUPPORT
import DashboardSupp from "../PagesCrm/AgentSupport/Dashboard";
import TableauSupp from "../PagesCrm/AgentSupport/Tableau";

//////////////////////////////////SECRETAIRE
import DashboardS from "../PagesCrm/Manager/PageDashboard";
import TableauS from "../PagesCrm/Secretaire/PageTableau";
import ParamsS from "../PagesCrm/Global/Users/PagesUsers";
import StatsS from "../PagesCrm/Manager/PageStatistiques";
import TargetsS from "../PagesCrm/Global/Targets/Targets";
import AlertsS from "../PagesCrm/Secretaire/Configurations/Alerts";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import Calls from "../PagesCrm/Global/TableCalls/Calls";
import Retraits from "../PagesCrm/Global/TableRetrait/Retraits";
import Refund from "../PagesCrm/Components/Refund/PageRefund";
import Tickets from "../PagesCrm/Global/Moon/Tickets/Tickets";
import Agrements from "../PagesCrm/Global/Moon/Agrements/Agrements"


import { ThemeContext } from "../context/ThemeContext";
import {
  getAdminProfil,
  getAdminDesks,
  getIdUsers,
} from "../PagesCrm/Redux/slices/admin.slice";
import {
  setSockets,
  setDringSockets,
  setListenAllDesks,
  getListenAllDesks
} from "../PagesCrm/Redux/slices/notifSocket.slice";

import { getShowRankSeller, setShowRankSeller } from "../PagesCrm/Redux/slices/actions.slice";

// import PagesPes from "../PagesCrm/Global/PagePes/PagesPes";

const DATA_SUBSCRIPTION = gql`
  subscription NotifsGlobal {
    NotifsGlobal {
      agt_supp_id
      content
      desk
      is_active
      idnotifs
      mng_supp_id
      sell_id
      timestamp
      tlead_id
      type
      user_id
      destinataire
    }
  }
`;

const DATA_SUB_DESK = (desk) => {
  return gql`
    subscription NotifsD${desk} {
      NotifsD${desk} {
        agt_supp_id
        content
        desk
        idnotifs
        is_active
        mng_supp_id
        sell_id
        timestamp
        tlead_id
        type
        user_id
        destinataire
      }
    }
  `;
};

const Markup = () => {
  const allroutes = [{ url: "app-calender", component: <Calendar /> }];
  const dispatch = useDispatch();

  const extractTextFromHTML = (html) => {
    // Utiliser une expression régulière pour supprimer toutes les balises HTML
    return html.replace(/<[^>]*>/g, "");
  };

  const choiceSub = (profilAdmin, desk) => {
    // console.log("profilAdmin", profilAdmin);
    let goodSub;
    switch (profilAdmin) {
      case "sell":
      case "tlead":
      case "agt_supp":
        // console.log("cas employes");
        // ces trois categorie ne doivent avoir qu'un seul desk
        goodSub = DATA_SUB_DESK(desk[0]);
        return goodSub;

      case "ass_mng":
      case "mng_supp":
      case "boss_supp":
      case "manager":
      case "admin":
      case "sadmin":
      default:
        // console.log("cas direction");
        goodSub = DATA_SUBSCRIPTION;

        return goodSub;
    }
  };

  const profil = useSelector(getAdminProfil);
  const GET_DESK = useSelector(getAdminDesks);
  const GET_ID_USERS = useSelector(getIdUsers);
  const { data: subscriptionData } = useSubscription(
    choiceSub(profil, GET_DESK)
  );

  useEffect(() => {
    // Normalisation de GET_DESK pour gérer les cas où c'est une chaîne ou un tableau
    const normalizedGET_DESK = Array.isArray(GET_DESK)
      ? GET_DESK.map(Number)
      : [Number(GET_DESK)];

    if (subscriptionData) {
      if (subscriptionData.NotifsGlobal) {
        // console.log("typeof(GET_DESK)", typeof (GET_DESK))
        // console.log("profil", profil)

        switch (profil) {
          case "sadmin":
          case "admin":
            dispatch(setSockets(subscriptionData?.NotifsGlobal));
            dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
            if (subscriptionData?.NotifsGlobal.type === "encaisse") {
              Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                  new Notification(`🤑 contrat encaisse 🤑`, {
                    body: `${extractTextFromHTML(
                      subscriptionData?.NotifsGlobal?.content
                    )}`,
                  });
                }
              });
            }
            break;

          case "mng_supp":
            if (
              normalizedGET_DESK.includes(
                Number(subscriptionData?.NotifsGlobal.desk)
              )
            ) {
              console.log(
                "subscriptionData?.NotifsGlobal.type",
                subscriptionData?.NotifsGlobal.type
              );
              if (subscriptionData?.NotifsGlobal.type === "encaisse") {
                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`🤑 contrat encaisse 🤑`, {
                      body: `${extractTextFromHTML(
                        subscriptionData?.NotifsGlobal?.content
                      )}`,
                    });
                  }
                });
              } else if (
                subscriptionData?.NotifsGlobal.type === "update-contract"
              ) {
                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`🚀 MAJ contrat 🚀`, {
                      body: `${extractTextFromHTML(
                        subscriptionData?.NotifsGlobal?.content
                      )}`,
                    });
                  }
                });
              } else if (
                subscriptionData?.NotifsGlobal.type === "new-contract"
              ) {
                // cas de la creation ou subscriptionData?.NotifsGlobal.type === new-contract

                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`🤤Nouveau contrat🤤`, {
                      body: `${subscriptionData?.NotifsGlobal?.content}`,
                    });
                  }
                });
              } else if (
                subscriptionData?.NotifsGlobal?.destinataire === GET_ID_USERS
              ) {
                /// cas de la creation ou subscriptionData?.NotifsGlobal.type === new-chat

                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(
                      `${
                        subscriptionData?.NotifsGlobal?.type === "new-chat"
                          ? "Nouveau chat vous concernant"
                          : subscriptionData?.NotifsGlobal?.type
                      }`,
                      {
                        body: `${subscriptionData?.NotifsGlobal?.content}`,
                      }
                    );
                  }
                });
              }
            }
            break;

          case "boss_supp":
            if (
              normalizedGET_DESK.includes(
                Number(subscriptionData?.NotifsGlobal.desk)
              )
            ) {
              console.log(
                "subscriptionData?.NotifsGlobal.type",
                subscriptionData?.NotifsGlobal.type
              );
              if (subscriptionData?.NotifsGlobal.type === "encaisse") {
                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`🤑 contrat encaisse 🤑`, {
                      body: `${extractTextFromHTML(
                        subscriptionData?.NotifsGlobal?.content
                      )}`,
                    });
                  }
                });
              } else if (
                subscriptionData?.NotifsGlobal.type === "update-contract"
              ) {
                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
                // dispatch(setSockets(subscriptionData?.NotifsGlobal));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`🚀 MAJ contrat 🚀`, {
                      body: `${extractTextFromHTML(
                        subscriptionData?.NotifsGlobal?.content
                      )}`,
                    });
                  }
                });
              } else if (
                subscriptionData?.NotifsGlobal.type === "new-contract"
              ) {
                // cas de la creation ou subscriptionData?.NotifsGlobal.type === new-contract
                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                // dispatch(setSockets(subscriptionData?.NotifsGlobal));
                // Notification.requestPermission().then(function (permission) {
                //   if (permission === 'granted') {
                //     new Notification(`🤤Nouveau contrat🤤`, {
                //       body: `${subscriptionData?.NotifsGlobal?.content}`,
                //     });
                //   }
                // });
              } else if (
                subscriptionData?.NotifsGlobal?.destinataire === GET_ID_USERS
              ) {
                /// cas de la creation ou subscriptionData?.NotifsGlobal.type === new-chat

                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(
                      `${
                        subscriptionData?.NotifsGlobal?.type === "new-chat"
                          ? "Nouveau chat vous concernant"
                          : subscriptionData?.NotifsGlobal?.type
                      }`,
                      {
                        body: `${subscriptionData?.NotifsGlobal?.content}`,
                      }
                    );
                  }
                });
              }
            }
            break;

          case "ass_mng":
          case "manager":
            if (subscriptionData?.NotifsGlobal.type === "new-contract") {
              // cette fonction sert uniquement pour l'ecran performance
              // console.log("ici")
              dispatch(setListenAllDesks(subscriptionData?.NotifsGlobal));
            }

            if (
              normalizedGET_DESK.includes(
                Number(subscriptionData?.NotifsGlobal.desk)
              )
            ) {
              console.log(
                "subscriptionData?.NotifsGlobal.type",
                subscriptionData?.NotifsGlobal.type
              );
              if (subscriptionData?.NotifsGlobal.type === "encaisse") {
                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`🤑 contrat encaisse 🤑`, {
                      body: `${extractTextFromHTML(
                        subscriptionData?.NotifsGlobal?.content
                      )}`,
                    });
                  }
                });
              } else if (
                subscriptionData?.NotifsGlobal.type === "update-contract"
              ) {
                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                // dispatch(setSockets(subscriptionData?.NotifsGlobal));
                // Notification.requestPermission().then(function (permission) {
                //   if (permission === 'granted') {
                //     new Notification(`🚀 MAJ contrat 🚀`, {
                //       body: `${ extractTextFromHTML(subscriptionData?.NotifsGlobal?.content) }`,
                //     });
                //   }
                // });
              } else if (
                subscriptionData?.NotifsGlobal.type === "new-contract"
              ) {
                // cas de la creation ou subscriptionData?.NotifsGlobal.type === new-contract

                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`🤤Nouveau contrat🤤`, {
                      body: `${subscriptionData?.NotifsGlobal?.content}`,
                    });
                  }
                });
              } else if (
                subscriptionData?.NotifsGlobal?.destinataire === GET_ID_USERS
              ) {
                /// cas de la creation ou subscriptionData?.NotifsGlobal.type === new-chat

                dispatch(setSockets(subscriptionData?.NotifsGlobal));
                dispatch(setDringSockets(subscriptionData?.NotifsGlobal));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(
                      `${
                        subscriptionData?.NotifsGlobal?.type === "new-chat"
                          ? "Nouveau chat vous concernant"
                          : subscriptionData?.NotifsGlobal?.type
                      }`,
                      {
                        body: `${subscriptionData?.NotifsGlobal?.content}`,
                      }
                    );
                  }
                });
              }
            }
            break;

          default:
            break;
        }
      } else {
        const dynamicKey = `NotifsD${normalizedGET_DESK[0]}`;
        const notifs = subscriptionData[dynamicKey];
        if (notifs) {
          console.log(notifs);
          switch (profil) {
            case "agt_supp":
              if (
                notifs.agt_supp_id === GET_ID_USERS &&
                notifs.type === "new-contract"
              ) {
                dispatch(setSockets(notifs));
                dispatch(setDringSockets(notifs));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`${notifs?.type}`, {
                      body: ` ${notifs?.content} `,
                    });
                  }
                });
                console.log("y'a un nouveau contract pour moi");
              } else if (notifs.agt_supp_id === GET_ID_USERS) {
                dispatch(setSockets(notifs));
                console.log(
                  "y'a une update, rien d'interessant car c'est moi qui l'ai initié"
                );
              } else if (
                notifs.type === "new-chat" &&
                notifs.destinataire === GET_ID_USERS
              ) {
                dispatch(setSockets(notifs));
                dispatch(setDringSockets(notifs));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`🗣 Nouveau chat vous concernant 🙊`, {
                      body: `${extractTextFromHTML(notifs?.content)}`,
                    });
                  }
                });
              }
              break;

            case "tlead":
              if (
                notifs.sell_id === GET_ID_USERS &&
                notifs.type === "new-contract"
              ) {
                // nouveau contrat de moi meme => pas de notif
                dispatch(setSockets(notifs));
              } else if (
                notifs.sell_id === GET_ID_USERS &&
                (notifs.type === "update-contract" ||
                  notifs.type === "encaisse")
              ) {
                // je suis le vendeur, et j'ai un contrat update ou encaisse
                dispatch(setSockets(notifs));
                dispatch(setDringSockets(notifs));

                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(
                      `${
                        notifs.type === "encaisse"
                          ? "🤑 contrat encaisse 🤑"
                          : "🚀 MAJ contrat 🚀"
                      }`,
                      {
                        body: `${extractTextFromHTML(notifs?.content)}`,
                      }
                    );
                  }
                });
              } else if (
                notifs.tlead_id === GET_ID_USERS &&
                (notifs.type === "new-contract" ||
                  notifs.type === "update-contract" ||
                  notifs.type === "encaisse")
              ) {
                // nouveau contrat de mon equipe
                dispatch(setSockets(notifs));
                dispatch(setDringSockets(notifs));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(
                      `${
                        notifs.type === "new-contract"
                          ? "🤤Nouveau contrat🤤"
                          : notifs.type === "encaisse"
                          ? "🤑 contrat encaisse 🤑"
                          : "🚀 MAJ contrat 🚀"
                      }`,
                      {
                        body: `${extractTextFromHTML(notifs?.content)}`,
                      }
                    );
                  }
                });
              } else if (
                notifs.type === "new-chat" &&
                notifs.destinataire === GET_ID_USERS
              ) {
                // chat personnelle pour le tlead
                dispatch(setSockets(notifs));
                dispatch(setDringSockets(notifs));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`🗣 Nouveau chat vous concernant 🙊`, {
                      body: `${extractTextFromHTML(notifs?.content)}`,
                    });
                  }
                });
              }
              break;

            case "sell":
              if (notifs.type === "new-contract") {
                console.log(notifs);
                console.log(subscriptionData[dynamicKey]);
                // cette fonction sert uniquement pour l'ecran performance
                // console.log("ici")
                dispatch(setListenAllDesks(subscriptionData[dynamicKey]));
              }
              if (
                notifs.sell_id === GET_ID_USERS &&
                notifs.type === "new-contract"
              ) {
                dispatch(setSockets(notifs));
                console.log(
                  "c'est mon contract, je ne veux pas de notif pour ça"
                );
                return;
              } else if (
                notifs.sell_id === GET_ID_USERS &&
                (notifs.type === "update-contract" ||
                  notifs.type === "encaisse")
              ) {
                dispatch(setSockets(notifs));
                dispatch(setDringSockets(notifs));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`${notifs?.type}`, {
                      body: `${extractTextFromHTML(notifs?.content)} `,
                    });
                  }
                });
                console.log("trop bien y'a une update sur mon contract");
              } else if (
                notifs.type === "new-chat" &&
                notifs.destinataire === GET_ID_USERS
              ) {
                dispatch(setSockets(notifs));
                dispatch(setDringSockets(notifs));
                Notification.requestPermission().then(function (permission) {
                  if (permission === "granted") {
                    new Notification(`🗣 Nouveau chat vous concernant 🙊`, {
                      body: `${notifs?.content}`,
                    });
                  }
                });
              }
              break;

            default:
              console.log("erreur dans la personne choisit");
              break;
          }
        }
      }
    }
  }, [subscriptionData, profil, GET_DESK, GET_ID_USERS, dispatch]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route element={<HomeLayout />}>
          <Route path="/notifs" element={<Notifs />} />
          <Route path="/chatAI" element={<ChatAI />} />
          <Route path="/Tickets" element={<Tickets />} />
          <Route path="/Agrements" element={<Agrements />} />
          {/* <Route path="/pes" element={<PagesPes />} /> */}
          {profil === "tlead" && (
            <>
              <Route path="/tl" element={<StatsTl />} />
              <Route path="/tl_stats" element={<DashboardTl />} />
              <Route path="/tl_tab" element={<TableauTl />} />
              <Route path="/tl_tab/:desk" element={<TableauTl />} />
              <Route path="/tl_tab/:desk/:idcontract" element={<TableauTl />} />
              <Route
                path="/tl_tab/:desk/:idcontract/:mode"
                element={<TableauTl />}
              />
              <Route path="/tl_params" element={<ParamsTl />} />
              <Route path="/tl_targets" element={<TargetsTl />} />
              <Route path="/tl_alerts" element={<AlertsTl />} />
              <Route path="/tl_calls" element={<Calls />} />
              <Route path="/tl_retraits" element={<Retraits />} />
              <Route path="/tl_document" element={<DocumentFindBoard />} />

            </>
          )}
          {profil === "sell" && (
            <>
              <Route path="/m_performance_Monthly_agent" element={<PagesPesMonthlyManager />}/>
              <Route path="/sell" element={<DashboardSell />} />
              <Route path="/sell_tab" element={<TableauSell />} />
              <Route path="/document" element={<DocumentFindBoard />} />
              <Route path="/sell_tab/:desk" element={<TableauSell />} />
              <Route path="/sell_tab/:desk/:idcontract" element={<TableauSell />} />
              <Route
                path="/sell_tab/:desk/:idcontract/:mode"
                element={<TableauSell />}
              />
            </>
          )}
          {profil === "agt_supp" && (
            <>
              <Route path="/supp" element={<DashboardSupp />} />
              <Route path="/supp_tab" element={<TableauSupp />} />
              <Route path="/supp_tab/:desk" element={<TableauSupp />} />
              <Route
                path="/supp_tab/:desk/:idcontract"
                element={<TableauSupp />}
              />
              <Route
                path="/supp_tab/:desk/:idcontract/:mode"
                element={<TableauSupp />}
              />
              <Route path="/document" element={<DocumentFindBoard />} />
            </>
          )}
          {profil === "mng_supp" && (
            <>
              <Route path="/ms" element={<CardsMS />} />
              <Route path="/ms/:desk" element={<CardsMS />} />
              <Route path="/ms_tab" element={<TableauMS />} />
              <Route path="/ms_tab/:desk" element={<TableauMS />} />
              <Route path="/ms_tab/:desk/:idcontract" element={<TableauMS />} />
              <Route
                path="/ms_tab/:desk/:idcontract/:mode"
                element={<TableauMS />}
              />
              <Route path="/ms_params" element={<ParamsMS />} />
              <Route path="/ms_params/:desk" element={<ParamsMS />} />
              <Route path="/ms_alerts" element={<AlertsMS />} />
              <Route path="/ms_alerts/:desk" element={<AlertsMS />} />
              <Route path="/ms_stats" element={<DashboardMS />} />
              <Route path="/ms_stats/:desk" element={<DashboardMS />} />
              <Route path="/document" element={<DocumentFindBoard />} />

            </>
          )}
          {profil === "admin" && (
            <>
              <Route path="/admin" element={<StatsAdmin />} />
              <Route path="/admin/:desk" element={<StatsAdmin />} />

              <Route path="/admin_stats" element={<DashboardAdmin />} />
              <Route path="/admin_stats/:desk" element={<DashboardAdmin />} />
              <Route path="/admin_tab" element={<TableauAdmin />} />
              <Route path="/admin_tab/:desk" element={<TableauAdmin />} />
              <Route
                path="/admin_tab/:desk/:idcontract"
                element={<TableauAdmin />}
              />
              <Route
                path="/admin_tab/:desk/:idcontract/:mode"
                element={<TableauAdmin />}
              />

              {/* <Route path="/admin_params" element={<ParamsAdmin />} /> */}
              <Route path="/admin_params/:desk" element={<ParamsAdmin />} />
              {/* <Route path="/admin_targets" element={<TargetsAdmin />} /> */}
              <Route path="/admin_targets/:desk" element={<TargetsAdmin />} />
              {/* <Route path="/admin_alerts" element={<AlertsAdmin />} /> */}
              <Route path="/admin_alerts/:desk" element={<AlertsAdmin />} />
              <Route path="/admin_psp" element={<PspAdmin />} />
              {/* <Route path="/admin_psp/:desk" element={<PspAdmin />} /> */}
              <Route path="/admin_bankclient" element={<BankClientAdmin />} />
              {/* <Route path="/admin_bankclient/:desk" element={<BankClientAdmin />} /> */}
              <Route path="/admin_neobank" element={<NeobankAdmin />} />
              {/* <Route path="/admin_neobank/:desk" element={<NeobankAdmin />} /> */}
              <Route path="/admin_brand" element={<BrandAdmin />} />
              {/* <Route path="/admin_brand/:desk" element={<BrandAdmin />} /> */}
              <Route path="/admin_desk" element={<DeskAdmin />} />
              {/* <Route path="/admin_desk/:desk" element={<DeskAdmin />} /> */}

              <Route path="/admin_desk_details" element={<DeskDetailAdmin />} />
              <Route
                path="/admin_desk_details/:desk"
                element={<DeskDetailAdmin />}
              />
              <Route path="/admin_calls/:desk" element={<Calls />} />
              <Route path="/admin_calls" element={<Calls />} />
              <Route path="/admin_retraits/:desk" element={<Retraits />} />
              <Route path="/admin_retraits" element={<Retraits />} />
              {/* <Route path="/m_stats/:desk" element={<DashboardM />} />
              <Route path="/m_tab/:desk" element={<TableauM />} />
              <Route path="/m_params/:desk" element={<ParamsM />} />
              <Route path="/m/:desk" element={<StatsM />} />
              <Route path="/m_targets/:desk" element={<TargetsM />} />
              <Route path="/m_alerts/:desk" element={<AlertsM />} /> */}
            </>
          )}
          {profil === "ass_mng" && (
            <>
              <Route path="/s" element={<DashboardS />} />
              <Route path="/s/:desk" element={<DashboardS />} />
              <Route path="/s_tab" element={<TableauS />} />
              <Route path="/s_tab/:desk" element={<TableauS />} />
              <Route path="/s_tab/:desk/:idcontract" element={<TableauS />} />
              <Route path="/s_tab/:desk/:idcontract/:mode" element={<TableauS />} />
              <Route path="/s_params" element={<ParamsS />} />
              <Route path="/s_params/:desk" element={<ParamsS />} />
              <Route path="/s_stats" element={<StatsS />} />
              <Route path="/s_stats/:desk" element={<StatsS />} />
              <Route path="/s_targets" element={<TargetsS />} />
              <Route path="/s_targets/:desk" element={<TargetsS />} />
              <Route path="/s_alerts" element={<AlertsS />} />
              <Route path="/s_alerts/:desk" element={<AlertsS />} />
              <Route path="/s_calls/:desk" element={<Calls />} />
              <Route path="/s_calls" element={<Calls />} />
              <Route path="/s_retraits/:desk" element={<Retraits />} />
              <Route path="/s_retraits" element={<Retraits />} />
              <Route path="/s_docs" element={<PagesDoc />} />
              <Route path="/s_refund" element={<Refund />} />

            </>
          )}
          {profil === "boss_supp" && (
            <>
              <Route path="/cs" element={<CardsCS />} />
              <Route path="/cs/:desk" element={<CardsCS />} />
              <Route path="/cs_tab" element={<TableauCS />} />
              <Route path="/cs_tab/:desk" element={<TableauCS />} />
              <Route path="/cs_tab/:desk/:idcontract" element={<TableauCS />} />
              <Route path="/cs_tab/:desk/:idcontract/:mode" element={<TableauCS />}/>
              <Route path="/cs_params" element={<ParamsCS />} />
              <Route path="/cs_params/:desk" element={<ParamsCS />} />
              <Route path="/cs_alerts" element={<AlertsCS />} />
              <Route path="/cs_alerts/:desk" element={<AlertsCS />} />
              <Route path="/cs_stats" element={<DashboardCS />} />
              <Route path="/cs_stats/:desk" element={<DashboardCS />} />
              <Route path="/cs_psp" element={<PspCS />} />
              <Route path="/cs_bankclient" element={<BankClientCS />} />
              <Route path="/cs_neobank" element={<NeobankCS />} />
              <Route path="/cs_brand" element={<BrandCS />} />
              <Route path="/cs_desk" element={<DeskCS />} />
              <Route path="/cs_calls/:desk" element={<Calls />} />
              <Route path="/cs_calls" element={<Calls />} />
              <Route path="/document" element={<DocumentFindBoard />} />
            </>
          )}
          {profil === "manager" && (
            <>
              <Route path="/m_stats" element={<DashboardM />} />
              <Route path="/m_stats/:desk" element={<DashboardM />} />
              <Route path="/m_tab" element={<TableauM />} />
              <Route path="/m_tab/:desk" element={<TableauM />} />
              <Route path="/m_tab/:desk/:idcontract" element={<TableauM />} />
              <Route
                path="/m_tab/:desk/:idcontract/:mode"
                element={<TableauM />}
              />
              <Route path="/m_performance" element={<PagesPes />} />
              <Route path="/m_performance/:desk" element={<PagesPes />} />
              <Route
                path="/m_performance_Monthly"
                element={<PagesPesMonthlyManager />}
              />
              <Route
                path="/m_performance_Monthly/:desk"
                element={<PagesPesMonthlyManager />}
              />
              <Route path="/m_tab" element={<TableauM />} />
              <Route path="/m_params" element={<ParamsM />} />
              <Route path="/m_params/:desk" element={<ParamsM />} />
              <Route path="/m" element={<StatsM />} />
              <Route path="/m/:desk" element={<StatsM />} />
              <Route path="/m_targets" element={<TargetsM />} />
              <Route path="/m_targets/:desk" element={<TargetsM />} />
              <Route path="/m_alerts" element={<AlertsM />} />
              <Route path="/m_alerts/:desk" element={<AlertsM />} />
              <Route path="/m_calls/:desk" element={<Calls />} />
              <Route path="/m_calls" element={<Calls />} />
              <Route path="/m_retraits/:desk" element={<Retraits />} />
              <Route path="/m_retraits" element={<Retraits />} />
              <Route path="/m_performance" element={<PagesPes />} />
              <Route path="/m_performance/:desk" element={<PagesPes />} />
              <Route path="/m_leads" element={<PagesLeads />} />
              <Route path="/m_leads/:desk" element={<PagesLeads />} />
              <Route path="/m_documents" element={<DocumentFindBoard />} />
            </>
          )}
        </Route>
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route element={<LayoutExtra />}>
          {/* <Route path="/blog" element={<Blog />} /> */}
        </Route>
      </Routes>
      {/* <ScrollToTop /> */}
    </>
  );
};

function HomeLayout() {
  const location = useLocation();
  const paddingClass =
    location.pathname === "/m_performance" ||
    location.pathname === "/m_performance_monthly"
      ? "p-0"
      : "p-3";
  const { menuToggle } = useContext(ThemeContext);
  const showRankSeller = useSelector(getShowRankSeller);
  const GETALLSOCKETS = useSelector(getListenAllDesks);


  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(setShowRankSeller(true));
    // console.log(":ici le s ")
  }, [GETALLSOCKETS]);

  const hideRankSellerRoutes = ["/m_performance", "/m_performance_monthly"];
  const currentPath = location.pathname;

  // Vérifier si la route actuelle doit cacher RankSeller
  const shouldHideRankSeller = hideRankSellerRoutes.includes(currentPath);



  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div className="outer-body">
        <div className="inner-body p-0">
          <div
            className="content-body"
            style={{ minHeight: window.screen.height - 150 }}
          >
            <div className={`${paddingClass}`}>
              <Outlet />
              {/* {showRankSeller && <RankSeller />} */}
              {/* {!shouldHideRankSeller && showRankSeller && <RankSeller />} */}
            </div>
          </div>
          {/* <Footer changefoot="home-footer" /> */}
        </div>
      </div>
    </div>
  );
}

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div className="outer-body">
        <div className="inner-body">
          <div
            className="content-body"
            style={{ minHeight: window.screen.height - 45 }}
          >
            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
          <Footer changefoot="home" />
        </div>
      </div>
    </div>
  );
}

function LayoutExtra() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div className="outer-body">
        <div className="inner-body">
          <div
            className="content-body"
            style={{ minHeight: window.screen.height - 45 }}
          >
            <div className="container">
              <Outlet />
            </div>
          </div>
          <Footer changefoot="home" />
        </div>
      </div>
    </div>
  );
}

export default Markup;
