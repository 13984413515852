import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { getAdminDetails, getAdminDesks, getToken, getPseudo, getAdminProfil } from "../../../Redux/slices/admin.slice";
import Cookies from 'js-cookie';
import localforage from 'localforage';
import config from "../../../../config/config";
import Loader from "../../../Components/LoaderPacMan";
import { Modal, Button, Form, Tabs, Tab } from "react-bootstrap";

const Agrements = () => {
  const rubrique = "AGR-";
  const AdminDetails = useSelector(getAdminDetails);
  const AdminDesks = useSelector(getAdminDesks);
  const AdminToken = useSelector(getToken);
  const PSEUDO = useSelector(getPseudo);
  const PROFIL = useSelector(getAdminProfil);
  const [openIframe, setOpenIframe] = useState({ open: false, url: "" });
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("Gap"); // État pour suivre l'onglet actif

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    IDclient: "",
    MTlogin: "",
    mail: "",
    montant: "",
    actif: "",
    cni: "",
    duree: "",
    renta: "",
    retrait: "",
    dateDecheance: "",
    nombreLot: "",
    typeContrat: activeTab,
    ligneCredit: false,
    montantLigneCredit: "",
    dateLigneCredit: ""
  });

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    console.log("Ouverture des Agrements ", AdminDetails);
    getTokenOauth();
  }, []);


  const getTokenOauth = async () => {
    try {
      const timestamp = Date.now();

      const urlOriginTrello = config.urlTrello;
      if (urlOriginTrello === "") {
        return
      }

      // Première requête pour obtenir le token
      const resOauth = await axios.get(`${urlOriginTrello}/api/v1/oauth.json?_=${timestamp}`);
      // console.log("resOauth.data", resOauth.data);

      const accessToken = resOauth.data.access_token;

      // Deuxième requête pour se connecter
      const resLogin = await axios.post(
        `${urlOriginTrello}/api/v1/users/login.json?token=${accessToken}`,
        {
          email: AdminDetails.pseudo,
          password: AdminDetails.idusers,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log("resLogin.data", resLogin.data);
      const { access_token, refresh_token, user } = resLogin.data;
      const { id, is_productivity_beats, initials, profile_picture_path, role_id,
        username, full_name, persist_card_divider_position, timezone,
        notify_count, last_activity_id, language, default_desktop_notification,
        is_list_notifications_enabled, is_card_notifications_enabled,
        is_card_members_notifications_enabled,
        is_card_labels_notifications_enabled,
        is_card_checklists_notifications_enabled,
        is_card_attachments_notifications_enabled,
        is_ldap, is_saml, is_intro_video_skipped,
        next_community_edition_popup_on, is_show_community_edition_popup,
        is_two_factor_authentication_enabled
      } = user;

      const filteredData = {
        access_token,
        refresh_token,
        user: {
          id,
          username,
          is_productivity_beats, initials, profile_picture_path, role_id,
          full_name, persist_card_divider_position, timezone, notify_count,
          last_activity_id, language, default_desktop_notification,
          is_list_notifications_enabled, is_card_notifications_enabled,
          is_card_members_notifications_enabled, is_card_labels_notifications_enabled,
          is_card_checklists_notifications_enabled, is_card_attachments_notifications_enabled,
          is_ldap, is_saml, is_intro_video_skipped, next_community_edition_popup_on,
          is_show_community_edition_popup, is_two_factor_authentication_enabled
        }
      };
      const encodedString = JSON.stringify(filteredData)
      // console.log("encodedString", encodedString);

      Cookies.set('auth', encodedString, { path: '/', domain: config.baseFront });
      Cookies.remove('push_tokens', { path: '/', domain: config.baseFront });

      let links = {};
      if (resLogin.data.links) {
        console.log("resLogin.data.links", resLogin.data.links);
        try {
          links = JSON.parse(resLogin.data.links);
          await localforage.setItem("links", links.links);
          //  console.log("l'action du localforage est terminee", resLogin.data.links)
        } catch (e) {
          console.error("Erreur lors du parsing de 'auth_response.links' :", e);
        }
      }

      Cookies.set('profil', PROFIL, { domain: config.baseFront });
      if (PROFIL === "manager") {

        // si le profil est manager on recupere tous les users de son desk pour les afficher dans le board trello

        const getAllDesksUsers = await axios.post(
          `${config.apiAdmin}/sadmin/users/UsersByDesksArrayCategories`,
          {
            desk: AdminDesks,
            categories: "SELLER"
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${AdminToken}`
            },
          }
        );
        // on fait cela pour  transformer un objet en Array
        const pseudos = getAllDesksUsers.data.map(item => item.pseudo);
        const arrayToStore = pseudos;
        const arrayString = JSON.stringify(arrayToStore);

        // on stocke les pseudos des users du desk dans le cookie
        Cookies.set('srcgoogle', arrayString, { domain: config.baseFront });
      } else if (PROFIL === "boss_supp" || PROFIL === "mng_supp") {
        // si le profil est boss_supp ou mng_supp on supprime le cookies srcgoogle afin d'afficher tout le monde
        Cookies.remove('srcgoogle', { domain: config.baseFront });
      } else {

        // cas ou c'est un user simple, alors il verra que ses propres tickets
        const arrayToStore = [PSEUDO];
        const arrayString = JSON.stringify(arrayToStore);
        Cookies.set('srcgoogle', arrayString, { domain: config.baseFront });
      }



      // recupere tous les boards de l'user
      const resBoards = await axios.get(`${urlOriginTrello}/api/v1/users/${resLogin.data.user.id}/boards.json?token=${resLogin.data.access_token}`);

      // va chercher le bon board ( cad le le plus grand board_id)
      const userBoards = resBoards.data.user_boards;
      console.log("userBoards", userBoards);

      const filteredBoards = userBoards.filter(board => board.organization_name.startsWith(rubrique));
      const largestBoard = filteredBoards.reduce((max, board) => {
        return parseInt(board.board_id) > parseInt(max.board_id) ? board : max;
      }, filteredBoards[0]);

      console.log(`Le board avec le plus grand board_id et organization_name commence par ${rubrique} :`, largestBoard);


      if (Cookies.get('auth') !== undefined) {
        if (largestBoard === undefined) {
          console.log(1)
          setOpenIframe(prevState => ({
            url: `${urlOriginTrello}/#/boards`,
            // url: `${urlOriginTrello}/#/boards`, // pour afficher tous les boards cas des managers
            open: true
          }));
        } else {
          console.log(2)
          const url = `${urlOriginTrello}/#/board/${largestBoard?.board_id}`;
          console.log("url", url);
          setOpenIframe(prevState => ({
            url,
            // url: `${urlOriginTrello}/#/boards`, // pour afficher tous les boards cas des managers
            open: true
          }));
        }
      }


    } catch (error) {
      console.error("Erreur lors des appels API :", error);
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    // Champs obligatoires globaux
    const globalRequiredFields = ["IDclient", "MTlogin", "mail", "nom", "prenom"];
    // Champs obligatoires spécifiques à l'onglet actif
    const tabSpecificRequiredFields = {
      Gap: ["cni", "actif", "nombreLot"],
      Debutant: ["montant", "actif", "renta", "dateDecheance", "nombreLot"],
      Statistique: ["duree", "cni", "renta", "retrait", "nombreLot"]
    };

    // Vérification des champs globaux
    for (const field of globalRequiredFields) {
      if (!formData[field]) {
        alert(`Le champ ${field} est obligatoire.`);
        return false;
      }
    }

    // Vérification des champs spécifiques à l'onglet actif
    const requiredFieldsForActiveTab = tabSpecificRequiredFields[activeTab];
    for (const field of requiredFieldsForActiveTab) {
      if (!formData[field]) {
        alert(`Le champ ${field} est obligatoire pour l'onglet ${activeTab}.`);
        return false;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log("Form Data: ", formData);
      axios.post(`${config.apiAdmin}/sadmin/moon/send_agrement`, formData, Header)
        .then(response => {
          console.log("Response: ", response);
          alert("Ticket créé avec succès !");
        })
        .catch(error => {
          console.error("Error: ", error);
          alert("Une erreur est survenue");
        });
      handleClose();
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Créer un demande D'agrement
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Créer une demande d'Agrements</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>


            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="formIDclient">
                  <Form.Label>ID Client</Form.Label>
                  <Form.Control
                    type="text"
                    name="IDclient"
                    placeholder="Entrez l'ID client"
                    value={formData.IDclient || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formMTlogin">
                  <Form.Label>MT Login</Form.Label>
                  <Form.Control
                    type="text"
                    name="MTlogin"
                    placeholder="Entrez le MT login"
                    value={formData.MTlogin || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="formNom">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    name="nom"
                    placeholder="Entrez le nom"
                    value={formData.nom || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formPrenom">
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="prenom"
                    placeholder="Entrez le prénom"
                    value={formData.prenom || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formMail">
                  <Form.Label>Mail</Form.Label>
                  <Form.Control
                    type="email"
                    name="mail"
                    placeholder="Entrez l'adresse mail"
                    value={formData.mail || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>

            </div>

            <Form.Group controlId="formSwitchLigneCredit" className="mt-3">
              <Form.Check
                type="switch"
                id="custom-switch-ligne-credit"
                label="Ligne de Crédit"
                checked={formData.ligneCredit || false}
                onChange={(e) => setFormData({ ...formData, ligneCredit: e.target.checked })}
              />
            </Form.Group>

            {formData.ligneCredit && (
              <>
                <Form.Group controlId="formMontantLigneCredit" className="mt-3">
                  <Form.Label>Montant Ligne de Crédit</Form.Label>
                  <Form.Control
                    type="number"
                    name="montantLigneCredit"
                    placeholder="Entrez le montant de la ligne de crédit"
                    value={formData.montantLigneCredit || ""}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formDateLigneCredit" className="mt-3">
                  <Form.Label>Date Ligne de Crédit</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateLigneCredit"
                    value={formData.dateLigneCredit || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            )}





            <br />

            <Tabs
              defaultActiveKey="Gap"
              id="uncontrolled-tab-example"
              className="my-3 py-2"
              onSelect={(k) => setActiveTab(k)} // Met à jour l'onglet actif
            >
              <Tab eventKey="Gap" title="Gap" className="p-3">
                <Form.Group controlId="formCNI">
                  <Form.Label>CNI</Form.Label>
                  <Form.Control
                    type="text"
                    name="cni"
                    placeholder="Entrez le CNI"
                    value={formData.cni || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formActif">
                  <Form.Label>Actif</Form.Label>
                  <Form.Control
                    type="text"
                    name="actif"
                    placeholder="Entrez l'actif"
                    value={formData.actif || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="nombreLot">
                  <Form.Label>Nombre de lot</Form.Label>
                  <Form.Control
                    type="number"
                    name="nombreLot"
                    placeholder="Entrez le nombre de lot"
                    value={formData.nombreLot || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Tab>
              <Tab eventKey="Debutant" title="Debutant" className="p-3">
                <Form.Group controlId="nombreLot">
                  <Form.Label>Nombre de lot</Form.Label>
                  <Form.Control
                    type="number"
                    name="nombreLot"
                    placeholder="Entrez le nombre de lot"
                    value={formData.nombreLot || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formMontant">
                  <Form.Label>Montant</Form.Label>
                  <Form.Control
                    type="number"
                    name="montant"
                    placeholder="Entrez le montant"
                    value={formData.montant || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formActif">
                  <Form.Label>Actif</Form.Label>
                  <Form.Control
                    type="text"
                    name="actif"
                    placeholder="Entrez l'actif"
                    value={formData.actif || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formRenta">
                  <Form.Label>Renta</Form.Label>
                  <Form.Control
                    type="text"
                    name="renta"
                    placeholder="Entrez la renta"
                    value={formData.renta || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formDateDecheance">
                  <Form.Label>Date de Déchéance</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateDecheance"
                    value={formData.dateDecheance || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Tab>
              <Tab eventKey="Statistique" title="Statistique" className="p-3">
                <Form.Group controlId="nombreLot">
                  <Form.Label>Nombre de lot</Form.Label>
                  <Form.Control
                    type="number"
                    name="nombreLot"
                    placeholder="Entrez le nombre de lot"
                    value={formData.nombreLot || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formDuree">
                  <Form.Label>Durée</Form.Label>
                  <Form.Control
                    type="text"
                    name="duree"
                    placeholder="Entrez la durée"
                    value={formData.duree || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formCNI">
                  <Form.Label>CNI</Form.Label>
                  <Form.Control
                    type="text"
                    name="cni"
                    placeholder="Entrez le CNI"
                    value={formData.cni || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formRenta">
                  <Form.Label>Renta</Form.Label>
                  <Form.Control
                    type="text"
                    name="renta"
                    placeholder="Entrez la renta"
                    value={formData.renta || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formRetrait">
                  <Form.Label>Retrait</Form.Label>
                  <Form.Control
                    type="text"
                    name="retrait"
                    placeholder="Entrez le retrait"
                    value={formData.retrait || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Tab>
            </Tabs>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Soumettre
          </Button>
        </Modal.Footer>
      </Modal>

      {openIframe.open
        ?
        <iframe src={openIframe.url} width="100%" height="1000px"></iframe>
        :
        <Loader />
      }
    </>
  );
};

export default Agrements;