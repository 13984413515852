import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config/config";
import { useSelector, useDispatch } from "react-redux";
import { setPopup } from "../../Redux/slices/actions.slice";
import { Offcanvas, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import useHeaderRest from "../../../utils/useHeader";
import { CircleLoader } from "react-spinners";
import { SVGICON } from "../../../jsx/constant/theme";

const NavSideDoc = () => {

  const useHeader = useHeaderRest();

  const [customer_id, setCustomer_id] = useState("");
  const [responseClient, setResponseClient] = useState();
  const [respNotFound, setRespNotFound] = useState(false);
  const initFormPost = {
    customer: {
      fname: "",
      lname: "",
      crm_id: ""
    },
    brand: ""
  }
  const [formPost, setFormPost] = useState(initFormPost);
  const [loaderButton, setLoaderButton] = useState(false);
  const [isVisibleDropVerifDoc, setIsVisibleDropVerifDoc] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isVisibleDropVerifDoc === false) {
      resetStates()
    }
  }, [isVisibleDropVerifDoc]);

  const resetStates = () => {
    // console.log("debut de resetStates")
    setCustomer_id("");
    setResponseClient(undefined);
    setRespNotFound(false)
    setFormPost(initFormPost)
    setLoaderButton(false);
  };

  const handleSubmit = (e) => {
    // premier submit pour trouver le client
    console.log("handleSubmit")
    e.preventDefault();
    if (customer_id === "") {
      alert("Veuillez entrer un customer_id")
      return
    }
    setLoaderButton(true);
    setRespNotFound(false)

    console.log("customer_id", customer_id)
    axios.post(`${config.apiAdmin}/sadmin/contracts/crmid_to_info_contracts`,
      { customer_id }
      , useHeader
    )
      .then(res => {
        setLoaderButton(false);

        if (res.data.code === "c8776") {
          // cas du not found
          setRespNotFound(true)

        } else {
          // cas ou j'ai de la data 
          // cacher le formulaire et afficher les infos du client avec un nouveau client
          setResponseClient(res.data.data)
        }
        console.log(res.data.data)
      })
      .catch(err => {
        console.log(err)
        setLoaderButton(false);
      })

  };

  const handleSendVerif = () => {
    // cas ou le crmId est trouver dans la base de donne, on envoie la demande de verification
    setLoaderButton(true);

    axios.post(`${config.apiAdmin}/sadmin/moon/verif_document`,
      {
        brand: responseClient.brand,
        customer: {
          crm_id: customer_id,
          fname: responseClient.fname,
          lname: responseClient.lname,
        }
      }
      , useHeader
    )
      .then(res => {
        setLoaderButton(false);
        dispatch(setPopup({
          message: "confirm",
          info: "La demande de verification a été envoyée avec succes",
          isActive: true,
        }));
        onHide();
      })
  }

  const handleSendVerifFull = () => {
    // cas ou on ne trouve pas le crmId dans la base de donne, on envoie la demande de verification
    setLoaderButton(true);

    if (formPost.customer.fname === "" || formPost.customer.lname === "" || formPost.brand === "") {
      alert("Veuillez remplir tous les champs")
      setLoaderButton(false)
      return
    }

    axios.post(`${config.apiAdmin}/sadmin/moon/verif_document`,
      formPost
      , useHeader
    )
      .then(res => {
        setLoaderButton(false);
        dispatch(setPopup({
          message: "confirm",
          info: "La demande de verification a été envoyée avec succes",
          isActive: true,
        }));
        onHide();
      })


  }

  const onHide = () => {
    setIsVisibleDropVerifDoc(false);
  }

  return (

    <>
      <div className="border bg-light rounded-1 mx-1 pointer" onClick={() => setIsVisibleDropVerifDoc(true)}>{SVGICON.IconKYC}</div>

      {isVisibleDropVerifDoc && (
        <Offcanvas
          show={isVisibleDropVerifDoc}
          onHide={() => {
            onHide();
            resetStates();
          }}
          placement="end"
          style={{ width: "600px" }}
        >
          <Offcanvas.Header closeButton className="pb-0 bg-light-update">
            <Offcanvas.Title className="col-10">
              <div className="d-flex">
                <div
                  className={`pointer fs-14 ms-2 me-3 p-2 b-bottom-green fw-bold`}
                >
                  Nouvelle demande de Verification Document
                </div>
              </div>
              <div className="border"></div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="bg-light-update">
            {
              !responseClient ?
                (
                  <>
                    {/* cas ou l'on demande le crmId */}
                    <div
                      style={{ textDecoration: "underline" }}
                      className="mb-3 fw-bold mt-1 text-black"
                    >
                      Veuillez entrer l'ID du client que vous pouvez trouver dans votre CRM
                    </div>
                    <form onSubmit={handleSubmit}>

                      <div className="grid-item p-0 me-1">
                        <div className="mx-2 mb-1">Customer ID</div>
                        <input
                          type="text"
                          onChange={(e) => setCustomer_id(e.target.value.trimStart())}
                          className={`form-control input-add`}
                        />
                      </div>

                      <div className="p-0 me-1 mt-4">
                        {
                          !respNotFound &&
                          (
                            <>
                              <button
                                type="submit"
                                disabled={loaderButton}
                                className="border btn btn-green col-12 text-black me-2"
                              >
                                {loaderButton
                                  ?
                                  <CircleLoader
                                    color="black"
                                    loading="true"
                                    size={32}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                  :
                                  "Valider"}
                              </button>
                            </>
                          )
                        }

                      </div>
                    </form>
                  </>
                )
                :
                (
                  <>
                    {/* cas ou l'on a trouve le crmId dans la base de donne */}
                    <div
                      style={{ textDecoration: "underline" }}
                      className="mb-3 fw-bold mt-1 text-black"
                    >
                      Est-ce bien le client que vous cherchez a verifier ?
                    </div>

                    <div className="grid-item p-0 me-1">
                      <div className="mx-2 mb-1">Prenom</div>
                      <input
                        type="text"
                        className={`form-control input-add }`}
                        value={responseClient.fname || ''}
                        readOnly
                        disabled={loaderButton}
                      />
                    </div>

                    <div className="grid-item p-0 me-1">
                      <div className="mx-2 mb-1">Nom</div>
                      <input
                        type="text"
                        className={`form-control input-add }`}
                        value={responseClient.lname || ''}
                        readOnly
                        disabled={loaderButton}
                      />
                    </div>

                    <div className="grid-item p-0 me-1">
                      <div className="mx-2 mb-1">Brand</div>
                      <input
                        type="text"
                        className={`form-control input-add }`}
                        value={responseClient.brand || ''}
                        readOnly
                        disabled={loaderButton}
                      />
                    </div>

                    <div className="p-0 me-1 mt-4">
                      <button
                        type="submit"
                        disabled={loaderButton}
                        className="border btn btn-green col-12 text-black me-2"
                        onClick={() => handleSendVerif()}
                      >
                        {loaderButton
                          ?
                          <CircleLoader
                            color="black"
                            loading="true"
                            size={32}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                          :
                          "Envoyer la verification"}
                      </button>
                    </div>

                  </>
                )
            }

            {
              respNotFound && (
                <>
                  {/* cas ou l'on a pas trouver le crmID dans le db, alors il faut renseigner le client */}
                  <div
                    style={{ textDecoration: "underline" }}
                    className="mb-3 fw-bold mt-1 text-black"
                  >
                    Votre Client n'existe pas dans notre system , veuillez remplir
                  </div>

                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Prénom</div>
                    <input
                      type="text"
                      className="form-control input-add"
                      value={formPost.customer.fname}
                      onChange={(e) =>
                        setFormPost({
                          ...formPost,
                          customer: {
                            ...formPost.customer,
                            fname: e.target.value,
                            crm_id: customer_id
                          },
                        })
                      }
                    />
                  </div>

                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Nom</div>
                    <input
                      type="text"
                      className={`form-control input-add }`}
                      disabled={loaderButton}
                      value={formPost.customer.lname}
                      onChange={(e) =>
                        setFormPost({
                          ...formPost,
                          customer: {
                            ...formPost.customer,
                            lname: e.target.value,
                            crm_id: customer_id
                          },
                        })
                      }
                    />
                  </div>

                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Brand</div>
                    <input
                      type="text"
                      className={`form-control input-add }`}
                      disabled={loaderButton}
                      value={formPost.brand}
                      onChange={(e) =>
                        setFormPost({
                          ...formPost,
                          brand: e.target.value
                        })
                      }
                    />
                  </div>

                  <div className="p-0 me-1 mt-4">
                    <button
                      type="submit"
                      disabled={loaderButton}
                      className="border btn btn-green col-12 text-black me-2"
                      onClick={() => handleSendVerifFull()}
                    >
                      {loaderButton
                        ?
                        <CircleLoader
                          color="black"
                          loading="true"
                          size={32}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                        :
                        "Envoyer la verification"}
                    </button>
                  </div>

                </>
              )
            }
          </Offcanvas.Body>
        </Offcanvas>
        )
      }
    </>

  );
};

export default NavSideDoc;