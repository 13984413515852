import React, { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import axios from "axios";
import config from "../../../config/config";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import TableauCrmFilters from "./TableauCrmFilters";
import {
  getAllContracts,
  setAllContracts,
  getFilters,
  setFilters,
} from "../../Redux/slices/contract.slice";
import formatNumber from "../../../utils/formatNumber";
import sortTable from "../../../utils/Table/sortTable";
import TrTable from "../../Global/Table/TrTable";
import AddContrat from "../../Global/Dropdowns/AddContracts";
import UpdateContracts from "../../Global/Table/UpdateContracts";
import { useParams } from "react-router-dom";
import initRow from "../../Manager/Tableau/TableauUtils/InitRowTableau";
import useHeader from "../../../utils/useHeader";
import { SVGICON } from "../../../jsx/constant/theme";
import { toTitleCase, formatDate, getDateClass, transformDate } from '../../Global/Table/Functions';

const TableauCrm = () => {
  const { idcontract } = useParams();

  const AdminDetails = useSelector(getAdminDetails);
  const desksToAdmin = AdminDetails?.desk;
  const profilToAdmin = AdminDetails?.profil;
  let Desk = desksToAdmin;

  const AllContracts = useSelector(getAllContracts);
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);

  const [showFilters, setShowFilters] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [isVisibleDropCreate, setIsVisibleDropCreate] = useState(false);
  const [contractSel, setContractSel] = useState([]);
  const [forComments, setForComments] = useState(false);
  const [closeFilters, setCloseFilters] = useState(false);
  const [modalRubrique, setModalRubrique] = useState("Contrat");
  const [sortRow, setSortRow] = useState(initRow);

  useEffect(() => {
    if (idcontract) {
      // cas ou j'ai cliquer sur le message dans le dropdown
      setShowModalUpdate(true);
      setContractSel(AllContracts[0]);
      return;
    }
    // cas normal
    setShowModalUpdate(false);
    setContractSel([]);
    GetAllContrats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idcontract, AllContracts]);

  const GetAllContrats = (data) => {
    if (data) {
      axios
        .post(
          `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
          filters,
          useHeader
        )
        .then((response) => {
          dispatch(setAllContracts(response.data.data.results));
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
    setCloseFilters(!closeFilters);
    dispatch(
      setFilters({
        filters: {
          desk: Desk,
        },
        filters_date: {},
        sorts: {},
        limit: "",
      })
    );
  };

  const handleRowClick = (event, row, index) => {
    setShowModalUpdate(!showModalUpdate);
    event.stopPropagation();
    setContractSel(row);
    setForComments(false);
    setModalRubrique("Contrat");
  };

  const handleLastColumnClick = (event, row, index) => {
    event.stopPropagation();
    setModalRubrique("Notes");
    setForComments(true);
    setContractSel(row);
    setShowModalUpdate(!showModalUpdate);
  };

  const stateRow = (row, type) => {
    if (sortRow[row].etat === 3) {
      setSortRow({ ...initRow, [row]: { etat: 1 } });
      sortTable(row, type, AllContracts, 1, dispatch, setAllContracts);
    } else if (sortRow[row].etat === 1) {
      setSortRow({ ...initRow, [row]: { etat: 2 } });
      sortTable(row, type, AllContracts, 2, dispatch, setAllContracts);
    } else if (sortRow[row].etat === 2) {
      setSortRow({ ...initRow, [row]: { etat: 3 } });
      sortTable(row, type, AllContracts, 3, dispatch, setAllContracts);
    }
  };

  const totalAmount2 = AllContracts?.reduce((sum, contract) => {
    if (
      (contract.is_report === 0 || contract.is_report === null) &&
      (contract.is_canceled === 0 || contract.is_canceled === null) &&
      (contract.encaisse === 0 || contract.encaisse === null)
    ) {
      return sum + (contract.amount || 0);
    }
    return sum;
  }, 0);
  const totalEncash2 = AllContracts?.reduce((sum, contract) => {
    if (
      (contract.is_report === 0 || contract.is_report === null) &&
      (contract.is_canceled === 0 || contract.is_canceled === null)
    ) {
      return sum + (contract.encash_usd || 0);
    }
    return sum;
  }, 0);
  const totalPreuve = AllContracts?.reduce((sum, contract) => {
    if (
      contract.preuve === 1 &&
      (contract.encaisse === 0 || contract.ecnaisse === null) &&
      (contract.is_report === 0 || contract.is_report === null) &&
      (contract.is_canceled === 0 || contract.is_canceled === null)
    ) {
      return sum + (contract.amount || 0);
    }
    return sum;
  }, 0);
  const totalReport = AllContracts?.reduce((sum, contract) => {
    if (
      contract.is_report === 1 &&
      (contract.is_canceled === 0 || contract.is_canceled === null)
    ) {
      return sum + (contract.amount || 0);
    }
    return sum;
  }, 0);
  const totalAnnul = AllContracts?.reduce((sum, contract) => {
    if (contract.is_canceled === 1) {
      return sum + (contract.amount || 0);
    }
    return sum;
  }, 0);
  const nbcontrats = AllContracts?.filter(
    (contract) => contract?.idcontracts?.length === 36
  )?.length;


  return (
    <div>
      <Card className="col-12 p-0">
        <Card.Header>
          <Card.Title className="col-12 p-0">
            <div className="d-flex align-items-center justify-content-between">
              <div>Tableau contrats</div>
              <div className="d-flex">
                <div className="fs-14 me-3">
                  nb lignes : {AllContracts?.length}
                </div>
                <div className="fs-14 me-3">nb contrats : {nbcontrats}</div>
                <div className="fs-14 me-3">
                  Reste a encaissé : {formatNumber(totalAmount2)} €{" "}
                </div>
                <div className="fs-14 me-3">
                  Preuve : {formatNumber(totalPreuve)} €{" "}
                </div>
                <div className="fs-14 me-3">
                  Encaissé : {formatNumber(totalEncash2)} ${" "}
                </div>
                <div className="fs-14 me-3">
                  Reporté : {formatNumber(totalReport)} €{" "}
                </div>
                <div className="fs-14">
                  Annulé : {formatNumber(totalAnnul)} €{" "}
                </div>
              </div>
              <div className="d-flex">
                <button
                  className="btn btn-primary py-0"
                  onClick={() => GetAllContrats({ data: true })}
                >
                  refresh
                </button>

                <div
                  className="border bg-light rounded-1 mx-1"
                  onClick={() => {
                    handleShowFilters();
                  }}
                >
                  {closeFilters ? (
                    <>{SVGICON.BiXIcon}</>
                  ) : (
                    <>{SVGICON.BiFunnelcon}</>
                  )}
                </div>

                <div
                  className="border bg-light rounded-1 mx-1"
                  onClick={() => {
                    setIsVisibleDropCreate(!showFilters);
                  }}
                >
                  {SVGICON.CroixPlus}
                </div>
              </div>
            </div>
            {showFilters && <TableauCrmFilters showFilters={showFilters} />}
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-scroll">
            <Table striped bordered hover>
              <thead>
                {sortRow && <TrTable sortRow={sortRow} stateRow={stateRow} />}
              </thead>
              <tbody className="table-container">
                {AllContracts?.map((row, index) => (
                  <tr
                    key={index}
                    onClick={(event) => {
                      if (profilToAdmin !== "sell") {
                        handleRowClick(event, row, index);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    className="tableau-content text-center"
                  >
                    <td className="fixed-column-td" title={row?.created_at}>
                      {row?.created_at
                        ? transformDate(row.created_at.slice(5, 10))
                        : ""}
                    </td>
                    <td className="fixed-column-td-2" title={row?.desk}>
                      {row?.desk}
                    </td>
                    <td
                      className="fixed-column-td-3 text-start"
                      title={row?.brand}
                    >
                      {toTitleCase(row?.brand)}
                    </td>
                    <td
                      className="fixed-column-td-4 fw-bold text-start text-black"
                      title={row?.cust_fname + " " + row?.cust_lname}
                    >
                      {`${row?.cust_lname} ${row?.cust_fname}`.trim().length >
                      40
                        ? `${toTitleCase(row?.cust_lname)} ${toTitleCase(
                            row?.cust_fname
                          )}`
                            .trim()
                            .substring(0, 30) + "..."
                        : `${toTitleCase(row?.cust_lname)} ${toTitleCase(
                            row?.cust_fname
                          )}`}
                    </td>
                    <td
                      title={row?.amount}
                      className="fixed-column-td-5 fw-bold text-end text-black"
                    >
                      {formatNumber(row?.amount)} €
                    </td>
                    <td
                      title={row?.pseudo_seller}
                      className="fixed-column-td-6 text-start border-right"
                    >
                      {toTitleCase(row?.pseudo_seller)}
                    </td>
                    <td
                      title={row?.alias_lname + " " + row?.alias_fname}
                      className="text-start"
                    >
                      {`${row?.alias_lname} ${row?.alias_fname}`.trim().length >
                      15
                        ? `${toTitleCase(row?.alias_lname)} ${toTitleCase(
                            row?.alias_fname
                          )}`
                            .trim()
                            .substring(0, 12) + "..."
                        : `${toTitleCase(row?.alias_lname)} ${toTitleCase(
                            row?.alias_fname
                          )}`}
                    </td>
                    <td
                      title={row?.paymentdudate}
                      className={`text-black text-end ${getDateClass(
                        row?.paymentdudate
                      )}`}
                    >
                      {formatDate(row?.paymentdudate)}
                    </td>
                    <td title={row?.pseudo_agt_supp} className="text-start">
                      {toTitleCase(row?.pseudo_agt_supp)}
                    </td>
                    <td title={row?.psp} className="text-start">
                      {toTitleCase(row?.psp)}
                    </td>
                    <td title={row?.neobank} className="text-start">
                      {toTitleCase(row?.neobank)}
                    </td>
                    <td
                      title={
                        row?.argent_dispo
                          ? "il es true"
                          : "il é pas encore cauché"
                      }
                      style={{ backgroundColor: "var(--bs-warning)" }}
                      className="text-center"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox2-1`}
                        name={`checkbox1-${index}`}
                        checked={row?.argent_dispo ? 1 : 0}
                        readOnly
                      />
                      {/* <label htmlFor={`checkbox2-${index}`}></label> */}
                    </td>
                    <td className="text-start">
                      {toTitleCase(row?.customerbank?.slice(0, 17))}
                    </td>
                    <td
                      style={{ backgroundColor: "var(--bs-yellow)" }}
                      className="text-center"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox2-2`}
                        name={`checkbox1-${index}`}
                        checked={row?.preuve ? 1 : 0}
                        readOnly
                      />
                    </td>
                    <td>
                      {row?.date_vir
                        ? transformDate(row.date_vir.slice(5, 10))
                        : ""}
                    </td>
                    <td
                      style={{ backgroundColor: "var(--bs-success)" }}
                      className="text-center"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox2-3`}
                        name={`checkbox1-${index}`}
                        checked={row?.encaisse ? 1 : 0}
                        readOnly
                      />
                      {/* <label htmlFor={`checkbox2-${index}`}></label> */}
                    </td>
                    <td>
                      {row?.date_encaisse
                        ? transformDate(row.date_encaisse.slice(5, 10))
                        : ""}
                    </td>
                    <td className="text-black fw-bold text-end">
                      {formatNumber(row?.encash_usd)} $
                    </td>
                    <td className="">
                      {row?.next_call
                        ? transformDate(row.next_call.slice(5, 10))
                        : ""}
                    </td>
                    <td>
                      {row?.hours === "00:00:00" ? "" : row?.hours?.slice(0, 5)}
                    </td>
                    <td
                      style={{ backgroundColor: "var(--bs-purple)" }}
                      className="text-center"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox2-4`}
                        name={`checkbox1-${index}`}
                        readOnly
                        checked={row?.is_report ? 1 : 0}
                      />
                      {/* <label htmlFor={`checkbox2-${index}`}></label> */}
                    </td>
                    <td
                      style={{ backgroundColor: "var(--bs-danger)" }}
                      className="text-center"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox2-5`}
                        name={`checkbox2-${index}`}
                        readOnly
                        checked={row?.is_canceled ? 1 : 0}
                      />
                      {/* <label htmlFor={`checkbox2-${index}`}></label> */}
                    </td>
                    <td>
                      <div className="text-center">
                        {row?.comments_timestamp
                          ? transformDate(row.comments_timestamp.slice(5, 10))
                          : ""}
                      </div>
                    </td>
                    <td
                      onClick={(event) =>
                        handleLastColumnClick(event, row, index)
                      }
                    >
                      <div className="text-start">{row?.comments}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <AddContrat
        isVisible={isVisibleDropCreate}
        onHide={() => setIsVisibleDropCreate(false)}
        data={AdminDetails}
        GetAllContrats={GetAllContrats}
      />
      {contractSel && (
        <UpdateContracts
          isVisible={showModalUpdate}
          onHide={() => {
            setShowModalUpdate(false);
            setContractSel();
            GetAllContrats({ data: true });
          }}
          data={contractSel}
          IsForComments={forComments}
          GetAllContrats={GetAllContrats}
          modalRubrique={modalRubrique}
        />
      )}
    </div>
  );
};

export default TableauCrm;
