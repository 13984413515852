import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const ChatAI = () => {
  const [messages, setMessages] = useState([{content: "Bonjour, comment puis-je vous aider ?", role: "assistant"}]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  const messagesEndRef = useRef(null);

  const getSimulatedResponse = (input) => {
    const responses = [
      "Je suis un bot, comment puis-je vous aider ?",
      "Pouvez-vous préciser votre question ?",
      "Je ne suis pas sûr de comprendre, pouvez-vous reformuler ?",
      "Merci pour votre message !",
      // "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem praesentium dolores fugit a nobis dolor est labore, pariatur temporibus aliquam tempore nesciunt. Adipisci ex ipsum nam autem laudantium libero blanditiis eos, unde itaque doloribus sit ullam quidem asperiores eius esse officiis facere expedita non odit, beatae ratione sint natus fuga.",
    ];
    return responses[Math.floor(Math.random() * responses.length)];
  };

  const sendMessage = async () => {
    if (!input.trim()) return;

    const newMessage = { role: "user", content: input };
    const updatedMessages = [...messages, newMessage];

    setMessages(updatedMessages);
    setInput("");
    setLoading(true);

    try {

      // Simuler une réponse
      const botReply = getSimulatedResponse(input);

      setMessages([...updatedMessages, { role: "assistant", content: botReply }]);
    } catch (error) {
      console.error("Erreur lors de la requête API :", error);
    } finally {
      setLoading(false);
    }

    
    // VERSION POUR CONNEXION AVEC API CHAT
    // try {
    //   const response = await fetch("https://api.openai.com/v1/chat/completions", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${API_KEY}`,
    //     },
    //     body: JSON.stringify({
    //       model: "gpt-3.5-turbo",
    //       messages: updatedMessages,
    //       temperature: 0.7,
    //     }),
    //   });

    //   const data = await response.json();
    //   const botReply = data.choices[0]?.message?.content || "Pas de réponse.";

    //   setMessages([...updatedMessages, { role: "assistant", content: botReply }]);
    // } catch (error) {
    //   console.error("Erreur lors de la requête API :", error);
    // } finally {
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-messages rounded shadow-lg">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`p-2 rounded-lg mb-2 ${
              msg.role === "user"
                ? "text-dark align-self-start my-3"
                : "bg-light-chat text-dark align-self-end shadow"
            }`}
          >
            <div
              style={
                msg.role === "user"
                  ? { paddingLeft: "20px", margin: "0 16px" }
                  : {}
              }
              className="d-flex align-items-center fs-5"
            >
              {msg.role !== "user" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-robot m-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.6 26.6 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.93.93 0 0 1-.765.935c-.845.147-2.34.346-4.235.346s-3.39-.2-4.235-.346A.93.93 0 0 1 3 9.219zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a25 25 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25 25 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135" />
                  <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2zM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5" />
                </svg>
              )}
              <div className="message-content">{msg.content}</div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input rounded shadow-lg">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Écrivez un message..."
          onKeyDown={(e) => e.key === "Enter" && sendMessage()}
        />
        <button onClick={sendMessage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-send"
            viewBox="0 0 16 16"
          >
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ChatAI;