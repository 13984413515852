import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config/config";
import {
    // getAdminDesks,
    // getAdminProfil,
    getAdminDetails
  } from "../../PagesCrm/Redux/slices/admin.slice";
  import {
    //  useDispatch,
     useSelector } from "react-redux";

const AlertsBar = () => {
  // const [alerts, setAlerts] = useState([]);
  const [content, setContent] = useState([]);

    // const dispatch = useDispatch();
    // const adminProfil = useSelector(getAdminProfil);
    // const adminDesks = useSelector(getAdminDesks);
    const adminDetails = useSelector(getAdminDetails);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const fetchAlerts = () => {
    // let id ;
    // let cat ;

    // switch (adminProfil) {
    //     case "sell":
    //       id = adminDetails.idusers;
    //       cat = "sell_id";
    //       break;
    //     case "agt_supp":
    //       id = adminDetails.idusers;
    //       cat = "agt_supp_id";
    //       break;
    //     case "tlead":
    //       id = adminDetails.idusers;
    //       cat = "tlead_id";
    //       break;
    //     case "mng_supp":
    //       id = adminDetails.idusers;
    //       cat = "mng_supp_id";
    //       break;
    //     default:
    //       // Handle default case if needed
    //       break;
    //   }

    let obj = {
      id: adminDetails.idusers,
      cat: "user_id"
    };

    if (obj.id === undefined) {
        // console.log("je usi sla")
        return
    }
    axios
      .post(`${config.apiAdmin}/sadmin/alerts/read_by_title`, obj, Header)
      .then((response) => {
        const contents = response?.data?.map((alert) => alert.content);
        setContent(contents);
        // setAlerts(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    fetchAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="d-flex"
        style={{  height: "40px" }}
      >
        <div className="alertsScrollContainer">
          {/* {content?.map((alertContent, index) => ( */}
            <div className="d-flex align-items-start">
              <div className="mx-5 text-alert fs-20 fw-bold text-black"></div>
            </div>
          
        </div>
      </div>
    </>
  );
};

export default AlertsBar;
