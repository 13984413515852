import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import config from "../../config/config";
import { useNavigate } from "react-router-dom";
import {
  setAdminProfil,
  setAdminAuthentified,
  setAdminDesks,
  setAdminBrands,
  setAdminDetails,
} from "../../PagesCrm/Redux/slices/admin.slice";
import { setTaux } from "../../PagesCrm/Redux/slices/taux.slice";

function Login(props) {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [twoFACode, setTwoFACode] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [errorLogin, setErrorLogin] = useState(false);

  const onLogin = (e) => {
    const objFetch = {
      pseudo: pseudo,
      pwd: password,
      otp: twoFACode,
    };
    e.preventDefault();
    axios
      .post(`${config.apiAdmin}/sadmin/users/sign_in`, objFetch)
      .then(function (response) {
        if (response.data) {
          // console.log(response.data);
          dispatch(setAdminProfil(response.data.profil));
          dispatch(setAdminAuthentified(true));
          dispatch(setAdminDesks(response.data.desk));
          dispatch(setAdminBrands(response.data.brands));
          dispatch(setAdminDetails(response.data));
          dispatch(
            setTaux({
              eur_usd: response.data.eur_usd,
              usd_eur: response.data.usd_eur,
            })
          );
          localStorage.setItem("tokenadmin", response.data.token);

          const adminProfil = response.data.profil;
          if (adminProfil === "sell") {
            navigate("/sell");
          } else if (adminProfil === "tlead") {
            navigate("/tl");
          } else if (adminProfil === "agt_supp") {
            navigate("/supp");
          } else if (adminProfil === "ass_mng") {
            navigate("/s");
          } else if (adminProfil === "mng_supp") {
            navigate("/ms");
          } else if (adminProfil === "boss_supp") {
            navigate("/cs");
          } else if (adminProfil === "manager") {
            navigate("/m");
          } else if (adminProfil === "admin") {
            navigate("/admin");
          } else if (adminProfil === "sadmin") {
            navigate("/sadmin");
          }
        }
      })
      .catch(function (error) {
        console.log(error.message);
        setErrorLogin(true);
      });
  };

  return (
    <div className="page-wraper">
      <div className="authincation">
        <div className="container-fluid">
          <div className={`row h-100 ${process.env.REACT_APP_CONFIG === "prodfrd" ? "bg-frd": "bg-star"} `}>
            <div className="col-lg-6 col-md-12 col-sm-12 mx-auto align-self-center">
              <div
                className="login-form card"
                style={{ bacgroundColor: "#b2b4b38f!important" }}
              >
                <div className="mb-3">
                  <h3 className="title fs-32 text-center">Login</h3>
                </div>
                <form onSubmit={onLogin}>
                  <div className="mb-4">
                    <label className="mb-1 text-dark">Pseudo</label>
                    <input
                      placeholder=""
                      type="pseudo"
                      className="form-control"
                      value={pseudo}
                      onChange={(e) => setPseudo(e.target.value)}
                      autoComplete="username"
                    />
                  </div>
                  <div className="mb-4 position-relative">
                    <label className="mb-1 text-dark">Password</label>
                    <input
                      type={`${showPassword ? "text" : "password"}`}
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                    />
                    <span
                      className="show-pass eye"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword === false ? (
                        <i className="fa fa-eye-slash" />
                      ) : (
                        <i className="fa fa-eye" />
                      )}
                    </span>
                  </div>
                  <div className="mb-4 position-relative">
                    <label className="mb-1 text-dark">2FA code</label>
                    <input
                      type={`text`}
                      className="form-control"
                      value={twoFACode}
                      onChange={(e) => setTwoFACode(e.target.value)}
                    />
                  </div>

                  {errorLogin && (
                    <div className="fs-16 text-red text-center">
                      Connection failed. Please try again.
                    </div>
                  )}

                  <div className="text-center mb-4">
                    <button className="button-login p-2"  type="submit">
                      Log in
                      <div className="star-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          version="1.1"
                          style={{
                            shapeRendering: "geometricPrecision",
                            textRendering: "geometricPrecision",
                            imageRendering: "optimizeQuality",
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                          }}
                          viewBox="0 0 784.11 815.53"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <defs></defs>
                          <g id="Layer_x0020_1">
                            <metadata id="CorelCorpID_0Corel-Layer"></metadata>
                            <path
                              className="fil0"
                              d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div className="star-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          version="1.1"
                          style={{
                            shapeRendering: "geometricPrecision",
                            textRendering: "geometricPrecision",
                            imageRendering: "optimizeQuality",
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                          }}
                          viewBox="0 0 784.11 815.53"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <defs></defs>
                          <g id="Layer_x0020_1">
                            <metadata id="CorelCorpID_0Corel-Layer"></metadata>
                            <path
                              className="fil0"
                              d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                            >
                              
                            </path>
                          </g>
                        </svg>
                      </div>
                      <div className="star-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          version="1.1"
                          style={{
                            shapeRendering: "geometricPrecision",
                            textRendering: "geometricPrecision",
                            imageRendering: "optimizeQuality",
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                          }}
                          viewBox="0 0 784.11 815.53"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <defs></defs>
                          <g id="Layer_x0020_1">
                            <metadata id="CorelCorpID_0Corel-Layer"></metadata>
                            <path
                              className="fil0"
                              d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div className="star-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          version="1.1"
                          style={{
                            shapeRendering: "geometricPrecision",
                            textRendering: "geometricPrecision",
                            imageRendering: "optimizeQuality",
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                          }}
                          viewBox="0 0 784.11 815.53"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <defs></defs>
                          <g id="Layer_x0020_1">
                            <metadata id="CorelCorpID_0Corel-Layer"></metadata>
                            <path
                              className="fil0"
                              d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div className="star-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          version="1.1"
                          style={{
                            shapeRendering: "geometricPrecision",
                            textRendering: "geometricPrecision",
                            imageRendering: "optimizeQuality",
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                          }}
                          viewBox="0 0 784.11 815.53"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <defs></defs>
                          <g id="Layer_x0020_1">
                            <metadata id="CorelCorpID_0Corel-Layer"></metadata>
                            <path
                              className="fil0"
                              d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div className="star-6">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          version="1.1"
                          style={{
                            shapeRendering: "geometricPrecision",
                            textRendering: "geometricPrecision",
                            imageRendering: "optimizeQuality",
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                          }}
                          viewBox="0 0 784.11 815.53"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <defs></defs>
                          <g id="Layer_x0020_1">
                            <metadata id="CorelCorpID_0Corel-Layer"></metadata>
                            <path
                              className="fil0"
                              d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </button>
                  </div>






                  <h6 className="login-title">
                    <span
                      style={{ textDecoration: "underline" }}
                      className="mx-2"
                    >
                      Forget your password
                    </span>
                  </h6>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // errorMessage: state.auth.errorMessage,
    // successMessage: state.auth.successMessage,
    // showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
