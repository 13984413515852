const config = {

  local: {
    baseFront: 'localhost',
    apiAdmin: 'http://localhost:8000',
    apiGraphQL: 'http://localhost:4000',
    apiGraphqlWs: `ws://localhost:4000`,
    urlTrello : 'https://trellf5t5wg9.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space'
  },

  prod: {
    baseFront: '.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
    apiAdmin: 'https://btvfi0hz4yb9tctu.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
    apiGraphQL: 'https://ut6dj890m0je60.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
    apiGraphqlWs: `wss://ut6dj890m0je60.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space`,
    urlTrello : ''
  },

  prodfrd: {
    baseFront: '.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
    apiAdmin: 'https://fny9wok418z57m.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
    apiGraphQL: 'https://fmva898a6tgmjn.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
    apiGraphqlWs: `wss://fmva898a6tgmjn.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space`,
    urlTrello : 'https://trellf5t5wg9.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space'
  },

  proddub: {
    baseFront: '.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
    apiAdmin: 'https://x1ohfj36zeghvk61.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
    apiGraphQL: 'https://fmvezyc8470670.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
    apiGraphqlWs: `wss://fmvezyc8470670.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space`,
    urlTrello : ''
  },

  test: {
    // il faudra les modifier des qu'on aura host
    baseFront: '.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
    apiAdmin: 'https://test9c7d9a084e42b9abcef462abfe8-q8k7q.ondigitalocean.app',
    apiGraphQL: 'https://ad99208c2fcb44c1b02b131582c3d657-xye2f.ondigitalocean.app',
    apiGraphqlWs: `wss://ad99208c2fcb44c1b02b131582c3d657-xye2f.ondigitalocean.app`,
    urlTrello : 'https://trel29test05.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space'
  },

};

export default config[process.env.REACT_APP_CONFIG];